define("job-ag/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    inclusion: '{description} er ikke en del af listen.',
    exclusion: '{description} er reserveret.',
    invalid: '{description} er ikke valid.',
    confirmation: "{description} matcher ikke {on}.",
    accepted: '{description} skal være accepteret.',
    empty: "{description} må ikke være tom.",
    blank: '{description} skal være tom.',
    present: "Feltet må ikke være tom.",
    collection: '{description} skal være flere.',
    singular: "{description} må ikke være flere.",
    tooLong: '{description} er for lang (maksimum is {max} tegn).',
    tooShort: '{description} er for kort (minimum er {min} tegn).',
    between: '{description} skal være imellem {min} og {max} tegn.',
    before: '{description} skal være før {before}.',
    onOrBefore: '{description} skal være på eller før {onOrBefore}.',
    after: '{description} skal være efter {after}.',
    onOrAfter: '{description} skal være på eller efter {onOrAfter}.',
    wrongDateFormat: '{description} skal være i følgende format {format}.',
    wrongLength: '{description} er forkert længde (skal være {is} tegn langt).',
    notANumber: '{description} skal være et tal.',
    notAnInteger: '{description} skal være et hel tal.',
    greaterThan: '{description} skal være større end {gt}.',
    greaterThanOrEqualTo: '{description} skal være støre end eller lig med {gte}.',
    equalTo: '{description} skal være lig med {is}.',
    lessThan: '{description} skal være mindre end {lt}.',
    lessThanOrEqualTo: '{description} skal være mindre end eller lig med {lte}.',
    otherThan: '{description} skal være anderledes end {value}.',
    odd: '{description} must be odd.',
    even: '{description} must be even.',
    positive: '{description} skal være positiv.',
    multipleOf: '{description} skal være flere af {multipleOf}.',
    date: '{description} skal være en dato.',
    email: '{description} skal være en email adresse.',
    phone: '{description} skal være et telefon nummer.',
    url: '{description} skal være et link.'
  };
  _exports.default = _default;
});