define("job-ag/templates/components/giant-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aRcCZTBv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"x_panel tile\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"x_title\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[[22,\"iconname\"],\"fas\"]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showClose\"]]],null,{\"statements\":[[0,\"      \"],[7,\"ul\",true],[10,\"class\",\"nav navbar-right panel_toolbox\"],[10,\"style\",\"justify-content: flex-end\"],[8],[0,\"\\n        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",false],[12,\"class\",\"close-link\"],[3,\"action\",[[23,0,[]],\"luk\"]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-close\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"x_content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12 pl-0 pr-0 form-group\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/giant-box.hbs"
    }
  });

  _exports.default = _default;
});