define("job-ag/services/utility", ["exports", "rxjs"], function (_exports, _rxjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Current tab select 
    activeTab: "pladser",
    // Get a subscription when there is a data fetch from data loader and wich tab it's from
    dataFetchSub: new _rxjs.Subject(),

    // Set name of active tab
    setActiveTab(tabId) {
      this.activeTab = tabId;
    },

    // Called to show a data fetch is occuring
    dataFetched(tabName) {
      this.dataFetchSub.next(tabName);
    },

    // Check for length of string compared to a default value
    checkSearchStartLength(text, select, searchStartLength) {
      if (select.searchText.length >= searchStartLength && text.length < searchStartLength) {
        return '';
      } else {
        return text.length >= searchStartLength;
      }
    },

    getDefaultDateRangeForDatePicker(options) {
      let daysToUse = 60;
      const defaultEndDate = new Date(Date.now());
      const defaultStartDate = new Date(defaultEndDate);

      if (options && options.hasOwnProperty("days") && options.days > 0) {
        daysToUse = options.days;
      }

      if (options && options.hasOwnProperty("splitPeriode") && options.splitPeriode) {
        daysToUse = Math.round(daysToUse / 2);
      }

      defaultStartDate.setDate(defaultEndDate.getDate() - daysToUse);
      defaultStartDate.setHours(0, 0, 0);

      if (options && options.hasOwnProperty("splitPeriode") && options.splitPeriode) {
        defaultEndDate.setDate(defaultEndDate.getDate() + daysToUse);
      }

      defaultEndDate.setHours(23, 59, 59);
      return {
        defaultStartDate: defaultStartDate,
        defaultEndDate: defaultEndDate
      };
    }

  });

  _exports.default = _default;
});