define("job-ag/controllers/log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchText: '',
    visningsType: 0,
    valgtStartdato: null,
    valgtSlutdato: null,
    searchWord: null,
    params: Ember.computed('searchWord', 'valgtStartdato', 'valgtSlutdato', 'visningsType', function () {
      let searchWord = Ember.get(this, 'searchFilter');
      let valgtStartdato = Ember.get(this, 'valgtStartdato');
      let valgtSlutdato = Ember.get(this, 'valgtSlutdato');
      let visningsType = Ember.get(this, 'visningsType');
      return {
        api: 'log',
        searchWord: searchWord,
        valgtStartdato: valgtStartdato,
        valgtSlutdato: valgtSlutdato,
        visningsType: visningsType
      };
    }),
    actions: {
      setSearchValue(searchText) {
        Ember.set(this, 'searchWord', searchText);
      },

      setDateRange(start, slut) {
        Ember.set(this, 'valgtStartdato', start);
        Ember.set(this, 'valgtSlutdato', slut);
      },

      setPage(newPage) {
        Ember.set(this, 'applicationPage', newPage);
        window.scrollTo(0, 0);
      }

    }
  });

  _exports.default = _default;
});