define("job-ag/templates/components/virksomheder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Leg34Pg/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row pt-5\"],[8],[0,\"\\n  \"],[5,\"filter-kommune\",[],[[\"@onChange\",\"@valgtKommune\",\"@classNames\",\"@searchStartLength\"],[[28,\"action\",[[23,0,[]],\"chosenKommune\"],null],[23,0,[\"valgtKommune\"]],\"col-md-4\",2]]],[0,\"\\n  \"],[5,\"filter-periode\",[],[[\"@valgtSlutdato\",\"@valgtStartdato\",\"@classNames\",\"@onChange\"],[[23,0,[\"selectedEndDate\"]],[23,0,[\"selectedStartDate\"]],\"col-md-4\",[28,\"action\",[[23,0,[]],\"chossenDateRange\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-7\"],[8],[0,\"\\n    \"],[5,\"small-box\",[],[[\"@iconname\",\"@title\",\"@contentClass\"],[\"chart-pie\",\"Samtaler\",\"boksMediumHeight\"]],{\"statements\":[[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"images/aktiviteterdiagram.png\"],[10,\"alt\",\"Oversigt over samtaler med virksomhederne\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-5 pl-5\"],[8],[0,\"\\n    \"],[5,\"small-box\",[],[[\"@iconname\",\"@title\",\"@contentClass\"],[\"map\",\"Kort\",\"boksMediumHeight\"]],{\"statements\":[[0,\"\\n      \"],[7,\"img\",true],[10,\"height\",\"250\"],[10,\"src\",\"images/kortmindre.png\"],[10,\"alt\",\"Virksomheder placeret på et kort over Danmark\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"iframe\",true],[10,\"width\",\"1550\"],[10,\"height\",\"1660\"],[10,\"style\",\"margin:auto;display:block;border:0\"],[10,\"src\",\"https://e09def3a.internetfirmaet.dk/kontrolpanel/virksomheder/demo/virksomheder4.aspx?load=true\"],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/virksomheder.hbs"
    }
  });

  _exports.default = _default;
});