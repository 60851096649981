define("job-ag/templates/components/footer-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o4qgr/HT",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[2,\" /menu footer buttons \"],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"sidebar-footer hidden-small\"],[8],[0,\"\\n             \"],[2,\"  <a data-toggle=\\\"tooltip\\\" data-placement=\\\"top\\\" title=\\\"\\\" data-original-title=\\\"Settings\\\">\\n                <span class=\\\"glyphicon glyphicon-cog\\\" aria-hidden=\\\"true\\\"></span>\\n              </a>\\n              <a data-toggle=\\\"tooltip\\\" data-placement=\\\"top\\\" title=\\\"\\\" data-original-title=\\\"FullScreen\\\">\\n                <span class=\\\"glyphicon glyphicon-fullscreen\\\" aria-hidden=\\\"true\\\"></span>\\n              </a>\\n              <a data-toggle=\\\"tooltip\\\" data-placement=\\\"top\\\" title=\\\"\\\" data-original-title=\\\"Lock\\\">\\n                <span class=\\\"glyphicon glyphicon-eye-close\\\" aria-hidden=\\\"true\\\"></span>\\n              </a>\\n              <a data-toggle=\\\"tooltip\\\" data-placement=\\\"top\\\" title=\\\"\\\" href=\\\"login.html\\\" data-original-title=\\\"Logout\\\">\\n                <span class=\\\"glyphicon glyphicon-off\\\" aria-hidden=\\\"true\\\"></span>\\n              </a> \"],[0,\"\\n            \"],[9],[0,\" \\n\"],[2,\" /menu footer buttons \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/footer-toolbar.hbs"
    }
  });

  _exports.default = _default;
});