define("job-ag/components/card-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    minheightClass: '',
    contentclasses: '',
    maksimeret: false,
    height: Ember.computed('maksimeret', function () {
      const maksimeret = Ember.get(this, 'maksimeret');
      const minheightClass = Ember.get(this, 'minheightClass');
      return maksimeret ? '' : minheightClass;
    }),
    actions: {
      toggleMax() {
        let maksimeret = Ember.get(this, 'maksimeret');
        Ember.set(this, 'maksimeret', !maksimeret);
      }

    }
  });

  _exports.default = _default;
});