define("job-ag/models/samtaler", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    pnummer: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    cvrnummer: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    // sagsbehandlerId: DS.attr('number', { defaultValue: 0 }),
    // sagbehandler: DS.attr('string', { defaultValue: '' }),
    // navnsagsbehandler: DS.attr('string', { defaultValue: '' }),
    beskrivelse: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    modifiedby: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    fvdbid: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    skaltilfvdb: _emberData.default.attr('boolean', {
      defaultValue: ''
    }),
    fvdb: _emberData.default.attr('boolean', {
      defaultValue: ''
    }),
    modifieddate: _emberData.default.attr('date', {
      defaultValue: ''
    }),
    naestekontakt: _emberData.default.attr('date', {
      defaultValue: ''
    }),
    samtaledato: _emberData.default.attr('date', {
      defaultValue: ''
    }),
    // firmanavn: DS.attr('string', { defaultValue: '' }),
    samtalestatus: _emberData.default.belongsTo('samtalestatus'),
    kontakttype: _emberData.default.belongsTo('kontakttype'),
    kontaktformaal: _emberData.default.belongsTo('kontaktformaal'),
    kontaktperson: _emberData.default.belongsTo('kontaktperson'),
    virksomheder: _emberData.default.belongsTo('virksomheder'),
    caseworker: _emberData.default.belongsTo('caseworker')
  });

  _exports.default = _default;
});