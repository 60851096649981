define("job-ag/components/samtaler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const optionsForPie = {
    "responsive": true,
    "legend": {
      fullwidth: false,
      position: 'right',
      display: true
    },
    "title": {
      fontSize: 18,
      fontFamily: 'sans-serif',
      display: false,
      text: ''
    },
    "animation": {
      animateScale: true,
      animateRotate: true
    }
  };
  const optionsForLine = {
    "responsive": true,
    "fill": false,
    "legend": {
      fullwidth: false,
      position: 'left',
      display: false
    },
    "title": {
      fontSize: 18,
      fontFamily: 'sans-serif',
      display: false,
      text: 'Status på aktive jobordrer'
    },
    "animation": {
      animateScale: true,
      animateRotate: true
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false,
          precision: 0
        }
      }]
    }
  };

  var _default = Ember.Component.extend({
    utility: Ember.inject.service(),
    store: Ember.inject.service(),
    dataFetchSub: null,
    formaalsOptions: optionsForPie,
    formaalsChartData: null,
    kontaktTypeOptions: optionsForPie,
    kontaktTypeChartData: null,
    samtaleStatusOptions: optionsForPie,
    samtaleStatusChartData: null,
    planlagteSamtalerPrDatoOptions: optionsForLine,
    planlagteSamtalerPrDatoChartData: null,
    selectedStartDate: null,
    selectedEndDate: null,
    selectedKommune: null,
    selectedTeam: null,
    selectedCaseworker: null,
    selectedCorporation: null,
    graphPlanlagteSamtalerPrDatoHasData: Ember.computed('planlagteSamtalerPrDatoChartData', function () {
      const graphData = this.planlagteSamtalerPrDatoChartData;
      return graphData !== null;
    }),
    graphSamtaleStatusHasData: Ember.computed('samtaleStatusChartData', function () {
      const graphData = this.samtaleStatusChartData;
      return graphData !== null;
    }),
    graphFormaalHasData: Ember.computed('formaalsChartData', function () {
      const graphData = this.formaalsChartData;
      return graphData !== null;
    }),
    graphKontaktTypeHasData: Ember.computed('kontaktTypeChartData', function () {
      const graphData = this.kontaktTypeChartData;
      return graphData !== null;
    }),
    params: Ember.computed('selectedStartDate', 'selectedEndDate', 'selectedKommune', 'selectedTeam', 'selectedCaseworker', 'selectedCorporation', function () {
      let data = {
        api: 'samtaler',
        startDate: "",
        endDate: "",
        kommune: "",
        caseworker: "",
        team: "",
        pnr: "",
        cvrnr: "",
        graphid: ""
      };

      try {
        data.startDate = Ember.isPresent(this.selectedStartDate) ? this.selectedStartDate.toJSON() : "";
        data.endDate = Ember.isPresent(this.selectedEndDate) ? this.selectedEndDate.toJSON() : "";
        data.kommune = Ember.isPresent(this.selectedKommune) ? this.selectedKommune.identifier : "";
        data.caseworker = Ember.isPresent(this.selectedCaseworker) ? this.selectedCaseworker.id : "";
        data.team = Ember.isPresent(this.selectedTeam) ? this.selectedTeam.id : "";
        data.pnr = Ember.isPresent(this.selectedCorporation) ? this.selectedCorporation.productionunitidentifier : "";
        data.cvrnr = Ember.isPresent(this.selectedCorporation) ? this.selectedCorporation.legalunitidentifier : "";
      } catch (error) {
        console.log("Error", error);
      }

      return data;
    }),

    init() {
      this._super(...arguments);

      const {
        defaultStartDate,
        defaultEndDate
      } = this.utility.getDefaultDateRangeForDatePicker({
        splitPeriode: true
      });
      Ember.set(this, 'selectedStartDate', defaultStartDate);
      Ember.set(this, 'selectedEndDate', defaultEndDate); // Subscribe to when Data Loader runs

      this.dataFetchSub = this.utility.dataFetchSub.subscribe(data => {
        if (data === "samtaler") {
          // 1 = Formåls type
          // 2 = Kontakt type
          // 3 = Samtale type/status
          // 4 = Planlagte samtaler pr. dato
          this.params.graphid = "1,2,3,4";
          this.store.query('graph', this.params).then(results => {
            if (results && results.length !== 0) {
              for (let i = 0; i < results.length; i++) {
                const graphData = results.objectAt(i);

                switch (graphData.id) {
                  case "1":
                    this.setFormaalsChartData(graphData);
                    break;

                  case "2":
                    this.setKontaktTypeChartData(graphData);
                    break;

                  case "3":
                    this.setSamtaleStatusChartData(graphData);
                    break;

                  case "4":
                    this.setPlanlagteSamtalerPrDatoChartData(graphData);
                    break;
                }
              }
            }
          });
        }
      });
    },

    willDestroyElement() {
      if (this.dataFetchSub) {
        this.dataFetchSub.unsubscribe();
      }
    },

    setFormaalsChartData(graphData) {
      let dataForGraph = null;

      if (graphData.values.length) {
        dataForGraph = {
          datasets: [{
            data: graphData.values,
            backgroundColor: graphData.colors
          }],
          labels: graphData.labels
        };
      }

      Ember.set(this, "kontaktTypeChartData", dataForGraph);
    },

    setKontaktTypeChartData(graphData) {
      let dataForGraph = null;

      if (graphData.values.length) {
        dataForGraph = {
          datasets: [{
            data: graphData.values,
            backgroundColor: graphData.colors
          }],
          labels: graphData.labels
        };
      }

      Ember.set(this, "formaalsChartData", dataForGraph);
    },

    setSamtaleStatusChartData(graphData) {
      let dataForGraph = null;

      if (graphData.values.length) {
        dataForGraph = {
          datasets: [{
            data: graphData.values,
            backgroundColor: graphData.colors
          }],
          labels: graphData.labels
        };
      }

      Ember.set(this, "samtaleStatusChartData", dataForGraph);
    },

    setPlanlagteSamtalerPrDatoChartData(graphData) {
      let dataForGraph = null;

      if (graphData.values.length) {
        dataForGraph = {
          datasets: [{
            data: graphData.values,
            backgroundColor: graphData.colors,
            fill: false
          }],
          labels: graphData.labels
        };
      }

      Ember.set(this, "planlagteSamtalerPrDatoChartData", dataForGraph);
    },

    actions: {
      chosenKommune(kommune) {
        Ember.set(this, 'selectedKommune', kommune);
      },

      chossenDateRange(start, slut) {
        Ember.set(this, 'selectedStartDate', start);
        Ember.set(this, 'selectedEndDate', slut);
      },

      chosenCaseworker(caseworker) {
        console.log(this.utility.activeTab);
        Ember.set(this, "selectedCaseworker", caseworker);
      },

      chosenTeam(team) {
        Ember.set(this, 'selectedTeam', team);
      },

      chosenCorporation(corporation) {
        Ember.set(this, 'selectedCorporation', corporation);
      }

    }
  });

  _exports.default = _default;
});