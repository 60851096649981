define("job-ag/services/settings", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    revisionDate: _environment.default.APP.BUILD_TIMESTAMP
  });

  _exports.default = _default;
});