define("job-ag/components/aktiviteter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const printThisOptions = {
    canvas: true
  };
  var chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
  };
  var chartoptions = {
    title: {
      display: false,
      text: 'blabla'
    },
    legend: false
  };
  const chartData = [{
    data: [300, 50, 100],
    backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
  }];

  var _default = Ember.Component.extend({
    utility: Ember.inject.service("utility"),
    store: Ember.inject.service("store"),
    selectedStartDate: null,
    selectedEndDate: null,
    selectedKommune: null,
    selectedTeam: null,
    selectedCaseworker: null,
    selectedCorporation: null,
    printThisOptions: printThisOptions,
    chartData: chartData,
    chartoptions: chartoptions,
    visData: false,
    visDataTitel: '',
    visDataId: -1,
    visDataComputed: Ember.computed('visData', function () {
      return this.visData;
    }),
    tabelData: Ember.computed('visDataId', function () {
      return [{
        id: 3121,
        joborderid: '',
        slut: '',
        branche: '',
        stilling: '',
        virksomhed: 'NCH SOUTH A/S',
        titel: 'Vil du arbejde for en af Københavns highend hoteller?',
        indrykket: '1. marts 2019'
      }, {
        id: 3120,
        joborderid: '',
        slut: '',
        branche: '',
        stilling: '',
        virksomhed: 'FRITIDS- OG UNGDOMSKLUB KVISTEN',
        titel: 'Kvisten - Medhjælper - Fritidsjob - Kommunal',
        indrykket: '1. marts 2019'
      }, {
        id: 3119,
        joborderid: '',
        slut: '',
        branche: '',
        stilling: '',
        virksomhed: 'Fakta 0304',
        titel: 'Skal hovedsageligt arbejde med Bake.Off',
        indrykket: '1. marts 2019'
      }, {
        id: 3118,
        joborderid: '',
        slut: '',
        branche: '',
        stilling: '',
        virksomhed: 'H.C. Andersen Festfyrværkeri ApS',
        titel: 'Bogføring & regnskabsassistance',
        indrykket: '1. marts 2019'
      }, {
        id: 3114,
        joborderid: '',
        slut: '',
        branche: '',
        stilling: '',
        virksomhed: 'SLAGTEHUSET NYKØBING F. ApS',
        titel: 'skal have erfaring med udskæring og skal kunne arbejde selvstændigt.',
        indrykket: '1. marts 2019'
      }];
    }),
    udenkandidaterData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Jobordre uden kandidater",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [20, 46, 46, 41, 38, 48, 42],
          spanGaps: false
        }]
      };
    }),

    handleChartClick(event, chartInstance) {
      try {
        const helpers = Chart.helpers;
        let eventPosition = helpers.getRelativePosition(event, chartInstance.chart);
        let y = chartInstance.chart.scales['y-axis-0'].getValueForPixel(eventPosition.y);
        let navn = chartInstance.chart.scales["y-axis-0"].ticks[y];
        Ember.set(this, 'visData', true);
        Ember.set(this, 'visDataId', y);
        Ember.set(this, 'visDataTitel', navn);
      } catch (err) {
        console.log(err);
      }
    },

    statusOptions: Ember.computed('statusData', function () {
      const componentContext = this;
      return {
        "responsive": true,
        onClick: function (event) {
          componentContext.handleChartClick(event, this);
        },
        "legend": {
          fullwidth: false,
          position: 'left',
          display: false
        },
        "title": {
          fontSize: 18,
          fontFamily: 'sans-serif',
          display: false,
          text: 'Status på aktive jobordrer'
        },
        "animation": {
          animateScale: true,
          animateRotate: true
        }
      };
    }),
    fetchStatusData: Ember.computed('model', function () {
      return this.store.findAll('diagramdata').then(alleDiagramData => {
        // because this is async you need to guard against the component 
        // being destroyed before the api call has finished because 
        // because `this.set` will throw an error.
        if (this.isDestroyed) {
          return;
        }

        this.set('statusDataRaw', alleDiagramData);
      });
    }),
    statusDataRaw: null,
    statusData: Ember.computed('model', function () {
      var diagramdata = Ember.get(this, 'statusDataRaw');
      var data = [];
      var labels = [];

      if (diagramdata) {
        var roots = diagramdata.map(function (dataset) {
          data.push(dataset.amount);
          labels.push(dataset.titel);
        });
      }

      return {
        datasets: [{
          data: data,
          backgroundColor: [chartColors.red, chartColors.orange, chartColors.yellow, chartColors.green, chartColors.blue],
          label: ''
        }],
        labels: labels
      };
    }),
    aktiviteterOptions: Ember.computed('aktiviteterData', function () {
      const componentContext = this;
      return {
        "responsive": true,
        onClick: function (event) {
          componentContext.handleChartClick(event, this);
        },
        "legend": {
          fullwidth: false,
          position: 'left',
          display: false,
          onClick: function () {
            alert("Hej");
          }
        },
        "title": {
          fontSize: 18,
          fontFamily: 'sans-serif',
          display: false,
          text: 'Status på aktive jobordrer'
        },
        "animation": {
          animateScale: true,
          animateRotate: true
        }
      };
    }),
    aktiviteterData: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [761, 220, 542, 1021, 201, 356, 120, 640, 731, 1435],
          backgroundColor: [],
          label: ''
        }],
        labels: ['Virksomhedspraktik (761)', 'Voksenelev (220)', 'Løntilskud (542)', 'Førtidspensionister(1021)', 'Fleksjob (201)', 'Nytteindsats (356)', 'Rotationsforløb/Pulje (120)', 'Rotationsforløb/alm (640)', 'Ordinært job(731)', 'Almindeligt job (1435)']
      };
    }),
    aktiviteter2Data: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [761, 220, 542, 1021, 201, 356, 120, 640, 731, 1435],
          backgroundColor: [],
          label: ''
        }],
        labels: ['Fleksjob (761)', 'Kontant og integration (220)', 'Dagpengemodtager (542)', 'Udd. og integration (210)', 'Sygedagpenge (1021)', 'Uddannelsesparat (150)', 'Ingen ydelse (356)']
      };
    }),
    jobopeningData: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [45, 22, 54, 21, 10, 15],
          backgroundColor: [],
          label: ''
        }],
        labels: ['Offentlige tilskud (45)', 'Private tilskudsjob (22)', 'Partnerskabsaftaler (54)', 'Ordinære job (21)', 'Integration (10)', 'Tilbud (15)']
      };
    }),
    antalAnsatData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Ansat af virksomheden",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [8, 2, 12, 17, 10, 14, 12],
          spanGaps: false
        }, {
          label: "Uden match",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255, 99, 132, 0.4)",
          borderColor: "rgba(255,99,132,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(255,99,132,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255,99,132,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [16, 8, 5, 12, 21, 14, 9],
          spanGaps: false
        }, {
          label: "Ikke mere relevant",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255, 206, 86, 0.4)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(255, 206, 86, 1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255, 206, 86, 1)",
          pointHoverBorderColor: "rgba(255, 206, 86, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [20, 12, 8, 10, 26, 12, 13],
          spanGaps: false
        }]
      };
    }),
    antalJobordreData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Antal tilgængelige jobordrer",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [220, 232, 240, 231, 228, 228, 232],
          spanGaps: false
        }]
      };
    }),
    params: Ember.computed('selectedStartDate', 'selectedEndDate', 'selectedKommune', 'selectedTeam', 'selectedCaseworker', 'selectedCorporation', function () {
      let data = {
        api: 'aktiviteter',
        startDate: "",
        endDate: "",
        kommune: "",
        caseworker: "",
        team: "",
        pnr: "",
        cvrnr: "",
        graphid: ""
      };

      try {
        data.startDate = Ember.isPresent(this.selectedStartDate) ? this.selectedStartDate.toJSON() : "";
        data.endDate = Ember.isPresent(this.selectedEndDate) ? this.selectedEndDate.toJSON() : "";
        data.kommune = Ember.isPresent(this.selectedKommune) ? this.selectedKommune.identifier : "";
        data.caseworker = Ember.isPresent(this.selectedCaseworker) ? this.selectedCaseworker.id : "";
        data.team = Ember.isPresent(this.selectedTeam) ? this.selectedTeam.id : "";
        data.pnr = Ember.isPresent(this.selectedCorporation) ? this.selectedCorporation.productionunitidentifier : "";
        data.cvrnr = Ember.isPresent(this.selectedCorporation) ? this.selectedCorporation.legalunitidentifier : "";
      } catch (error) {
        console.log("Error", error);
      }

      return data;
    }),

    init() {
      this._super(...arguments);

      const {
        defaultStartDate,
        defaultEndDate
      } = this.utility.getDefaultDateRangeForDatePicker({
        splitPeriode: true
      });
      Ember.set(this, 'selectedStartDate', defaultStartDate);
      Ember.set(this, 'selectedEndDate', defaultEndDate);
    },

    actions: {
      chosenKommune(kommune) {
        Ember.set(this, 'selectedKommune', kommune);
      },

      chossenDateRange(start, slut) {
        Ember.set(this, 'selectedStartDate', start);
        Ember.set(this, 'selectedEndDate', slut);
      },

      chosenCaseworker(caseworker) {
        console.log(this.utility.activeTab);
        Ember.set(this, "selectedCaseworker", caseworker);
      },

      chosenTeam(team) {
        Ember.set(this, 'selectedTeam', team);
      },

      chosenCorporation(corporation) {
        Ember.set(this, 'selectedCorporation', corporation);
      }

    }
  });

  _exports.default = _default;
});