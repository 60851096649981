define("job-ag/models/pladser", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tilbudtitel: _emberData.default.attr('string'),
    antalpladserialt: _emberData.default.attr('number'),
    antalledigepladser: _emberData.default.attr('number'),
    foerstkommendeslutdatoloentilskud: _emberData.default.attr(),
    check: _emberData.default.attr('string'),
    sted: _emberData.default.attr('string'),
    adresse: _emberData.default.attr('string'),
    postnr: _emberData.default.attr('number'),
    by: _emberData.default.attr('string'),
    telefonnr: _emberData.default.attr('string'),
    beskrivelse: _emberData.default.attr('string'),
    offentlig: _emberData.default.attr('boolean'),
    reserveret: _emberData.default.attr('boolean'),
    note: _emberData.default.attr('string'),
    cvrnr: _emberData.default.attr('string'),
    pnr: _emberData.default.attr('string'),
    tilbudstype: _emberData.default.attr('string'),
    tilbudstypeID: _emberData.default.attr('number'),
    fagkode: _emberData.default.attr('string'),
    kommunepladser: _emberData.default.attr('string'),
    stillingsbetegnelse: _emberData.default.attr('string'),
    gyldighedsperiodestart: _emberData.default.attr(),
    gyldighedsperiodeslut: _emberData.default.attr(),
    checkopskrivning: _emberData.default.attr('string'),
    kanbookes: _emberData.default.attr('boolean'),
    kanoverbookes: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('number'),
    tilbudssted: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean'),
    timestamp: _emberData.default.attr('string'),
    externbeskrivelse: _emberData.default.attr('string'),
    teaser: _emberData.default.attr('string'),
    ansvarlig: _emberData.default.attr('number'),
    oprettetaf: _emberData.default.attr('number'),
    tilbudskategorinavn: _emberData.default.attr('string'),
    fagomraade: _emberData.default.attr('number'),
    faggruppe: _emberData.default.attr('number'),
    kategoriid: _emberData.default.attr('number'),
    hjemmesidetitel: _emberData.default.attr('string'),
    kalender: _emberData.default.attr('boolean'),
    kontaktfornavn: _emberData.default.attr('string'),
    kontaktefternavn: _emberData.default.attr('string'),
    kontaktemail: _emberData.default.attr('string'),
    kontakttelefon: _emberData.default.attr('string'),
    jobkonsulent: _emberData.default.attr('string')
  });

  _exports.default = _default;
});