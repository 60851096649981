define("job-ag/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "job-ag/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.APP.serverTokenEndpoint
  });

  _exports.default = _default;
});