define("job-ag/models/primaryrecruitmentauthoritiestype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    authorityCodeField: _emberData.default.attr('string'),
    organisationTypeIdentifierField: _emberData.default.attr('number'),
    //	primaryRecruitmentAuthoritiesField 		: DS.hasMany('joborder'),
    organisationTypeName: _emberData.default.attr('string'),
    authorityName: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    postCodeIdentifier: _emberData.default.attr('string'),
    districtSubdivisionIdentifier: _emberData.default.attr('string'),
    telephoneNumberIdentifier: _emberData.default.attr('string'),
    faxNumberIdentifier: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    webAddress: _emberData.default.attr('string')
  });

  _exports.default = _default;
});