define("job-ag/router", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('joborder', function () {
      this.route('new');
    });
    this.route('login');
    this.route('users');
    this.route('settings', function () {
      this.route('new');
      this.route('edit', {
        path: '/edit/:settingid'
      });
      this.route('delete', {
        path: '/delete/:settingid'
      });
    });
    this.route('statistik');
    this.route('jobordre', {
      path: '/jobordre/:joborder_id'
    }, function () {
      this.route('borger', function () {
        this.route('delete', {
          path: '/delete/:borger_id'
        });
        this.route('edit', {
          path: '/edit/:borger_id'
        });
        this.route('new');
      });
    });
    this.route('brugere', function () {
      this.route('edit', {
        path: '/edit/:accountid'
      });
      this.route('delete', {
        path: '/delete/:accountid'
      });
      this.route('new');
    });
    this.route('log');
    this.route('startsider', function () {
      this.route('new');
      this.route('edit', {
        path: '/edit/:startsideid'
      });
      this.route('delete', {
        path: '/delete/:startsideid'
      });
    });
    this.route('skiftbrugervalg');
    this.route('skiftpassword');
    this.route('dashboard');
    this.route('aktiviteter');
    this.route('jobaabninger');
    this.route('pladser');
    this.mount('frontend');
    this.route('demo', function () {
      this.route('joborder');
    });
    this.route('virksomhedsprofil');
  });
  var _default = Router;
  _exports.default = _default;
});