define("job-ag/controllers/joborder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    searchWord: '',
    valgtStartdato: null,
    valgtSlutdato: null,
    valgtKommune: null,
    valgtBranche: null,
    valgtStatus: null,
    valgtArbejdstid: null,
    filtreret: Ember.computed('valgtKommune', 'valgtBranche', 'valgtStatus', 'valgtArbejdstid', 'searchfilter', function () {
      //	console.log("Opdater liste...");
      const searchWord = Ember.get(this, 'searchWord').toLowerCase();
      const kommune = Ember.get(this, 'valgtKommune.identifier');
      const branche = Ember.get(this, 'valgtBranche.identifier');
      const status = Ember.get(this, 'valgtStatus.identifier');
      const arbejdstid = Ember.get(this, 'valgtArbejdstid.identifier');
      const joborders = Ember.get(this, 'model.joborder');
      return joborders.filter(function (item) {
        let relevant = true;

        if (status) {
          relevant = relevant && Ember.get(item, 'companyRecruitmentStatusTypeIdentifierField').toString() == status;
        }

        if (kommune) {
          let kommuneInt = parseInt(kommune, 10);

          if (kommuneInt > 9) {
            //debugger;
            try {
              relevant = relevant && Ember.get(item, 'responsibleJobcenterCodeField').toString() == kommune + "00";
            } catch (exception) {
              relevant = false;
            }
          }
        }

        if (branche) {
          relevant = relevant && Ember.get(item, 'occupationIdField').toString() == branche;
        }

        if (arbejdstid) {
          relevant = relevant && Ember.get(item, 'weeklyWorkTimeTypeIdentifierField').toString() == arbejdstid;
        }

        if (search) {
          let contains = Ember.get(item, 'companyRecruitmentTitleField') && Ember.get(item, 'companyRecruitmentTitleField').toLowerCase().indexOf(search) > -1;
          contains = contains || Ember.get(item, 'companyRecruitmentJobDescriptionField') && Ember.get(item, 'companyRecruitmentJobDescriptionField').toLowerCase().indexOf(search) > -1;
          contains = contains || Ember.get(item, 'companyRecruitmentLocationNameField') && Ember.get(item, 'companyRecruitmentLocationNameField').toLowerCase().indexOf(search) > -1;
          contains = contains || Ember.get(item, 'companyRecruitmentCommentField') && Ember.get(item, 'companyRecruitmentCommentField').toLowerCase().indexOf(search) > -1;
          contains = contains || Ember.get(item, 'internalAuthorityCommentField') && Ember.get(item, 'internalAuthorityCommentField').toLowerCase().indexOf(search) > -1;
          relevant = relevant && contains;
        }

        return relevant;
      });
    }),
    params: Ember.computed('searchWord', 'valgtKommune', 'valgtBranche', 'valgtStatus', 'valgtArbejdstid', function () {
      let searchWord = this.searchWord;
      let valgtKommune = this.valgtKommune;
      let kommuneId = valgtKommune ? valgtKommune.identifier : -1;
      let valgtBranche = this.valgtBranche;
      let brancheId = valgtBranche ? valgtBranche.identifier : -1;
      let valgtStatus = this.valgtStatus;
      let statusId = valgtStatus ? valgtStatus.identifier : -1;
      let valgtArbejdstid = this.valgtArbejdstid;
      let arbejdstidId = valgtArbejdstid ? valgtArbejdstid.identifier : -1;
      return {
        api: 'joborder',
        searchWord: searchWord,
        arbejdstidId: arbejdstidId,
        statusId: statusId,
        brancheId: brancheId,
        kommuneId: kommuneId
      };
    }),
    actions: {
      submit() {//	console.log(`submit filters`);
      },

      onJoborderClick(item) {
        this.transitionToRoute('jobordre', item);
      },

      setDateRange(start, slut) {
        Ember.set(this, 'valgtStartdato', start);
        Ember.set(this, 'valgtSlutdato', slut);
      },

      setSearchValue(searchWord) {
        Ember.set(this, 'searchWord', searchWord);
      },

      setKommune(kommune) {
        Ember.set(this, 'valgtKommune', kommune);
      },

      setBranche(branche) {
        Ember.set(this, 'valgtBranche', branche);
      },

      setStatus(status) {
        Ember.set(this, 'valgtStatus', status);
      },

      setArbejdstid(arbejdstid) {
        Ember.set(this, 'valgtArbejdstid', arbejdstid);
      },

      markerClick(joborder) {
        this.transitionToRoute("jobordre", joborder.id);
      }

    },

    branchematcher(branche, term) {
      return (branche.data.name + ' ' + branche.data.identifier).indexOf(term);
    }

  });

  _exports.default = _default;
});