define("job-ag/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    indhold: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    culture: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    eventid: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    standerid: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    skabelonid: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    visningstid: _emberData.default.attr('number'),
    content: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    eventidAsString: Ember.computed('eventid', {
      get() {
        return `${this.eventid}`;
      }

    }),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});