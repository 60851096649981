define("job-ag/templates/components/filter-virksomhedsroller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2sMja+V",
    "block": "{\"symbols\":[\"rolle\"],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\"],[\"tags\",\"Virksomhedsroller\"]],{\"statements\":[[0,\"\\n\"],[4,\"data-power-select\",null,[[\"modelName\",\"queryKey\",\"searchEnabled\",\"loadingMessage\",\"searchMessage\",\"noMatchesMessage\",\"onChange\",\"selected\",\"placeholder\",\"triggerClass\",\"dropdownClass\",\"allowClear\"],[\"rollertilvirksomhed\",\"searchWord\",true,\"Indlæser...\",\"Indtast rollens navn i søgefeltet\",\"Ingen rolle opfylder søgekriteriet\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"valgtRolle\"]]],null]],null],[24,[\"valgtRolle\"]],\"Alle\\troller\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-virksomhedsroller.hbs"
    }
  });

  _exports.default = _default;
});