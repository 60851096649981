define("job-ag/initializers/preloader", ["exports", "ember-cli-preloader/services/preloader"], function (_exports, _preloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    run
  } = Ember;
  var _default = {
    name: 'preloader',
    initialize: function (app) {
      let options = app.preloader || {};

      let service = _preloader.default.create({
        options: options
      });

      app.register('service:preloader', service, {
        instantiate: false
      });
    }
  };
  _exports.default = _default;
});