define("job-ag/models/virksomheder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    // ajourfoeringsmarkering: DS.attr('string', { defaultValue: '' }),
    // startdate: DS.attr('string', { defaultValue: '' }),
    // pil: DS.attr('number', { defaultValue: '' }),
    // mainactivityactivitycode: DS.attr('string', { defaultValue: '' }),
    // mainactivityactivitydescription: DS.attr('string', { defaultValue: '' }),
    // employmentquarteremploymentintervalcode: DS.attr('string', { defaultValue: '' }),
    // businessformatdescription: DS.attr('string', { defaultValue: '' }),
    // creditorstatusinformationcode: DS.attr('string', { defaultValue: '' }),
    // telephonenumberidentifier: DS.attr('string', { defaultValue: '' }),
    // telephonenumberidentifier1: DS.attr('string', { defaultValue: '' }),
    legalunitidentifier: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    productionunitidentifier: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    addressofficialstreetname: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    addressofficialpostcodeidentifier: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    addressofficialdistrictname: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    addressofficialstreetbuildingidentifier: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    address: Ember.computed('addressofficialstreetname', 'addressofficialpostcodeidentifier', 'addressofficialdistrictname', 'addressofficialstreetbuildingidentifier', function () {
      const streetname = Ember.get(this, 'addressofficialstreetname') || '';
      const buildingnumber = Ember.get(this, 'addressofficialstreetbuildingidentifier');
      const postalCode = Ember.get(this, 'addressofficialpostcodeidentifier') || '';
      const cityname = Ember.get(this, 'addressofficialdistrictname');
      return `${streetname} ${buildingnumber}, ${postalCode} ${cityname}`;
    })
  });

  _exports.default = _default;
});