define("job-ag/models/kontakttype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    kontakttype: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    defaultsamtaleemne: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    position: _emberData.default.attr('number', {
      defaultValue: ''
    })
  });

  _exports.default = _default;
});