define("job-ag/components/link-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    alleLink: null,

    init() {
      this._super(...arguments);

      this.alleLink = [];

      this._getLink();
    },

    _getLink() {
      this.get('store').findAll('link').then(alleLink => {
        // because this is async you need to guard against the component 
        // being destroyed before the api call has finished because 
        // because `this.set` will throw an error.
        if (this.isDestroyed) {
          return;
        }

        this.set('alleLink', alleLink);
      });
    }

  });

  _exports.default = _default;
});