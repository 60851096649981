define("job-ag/templates/components/filter-virksomhed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zu4raqho",
    "block": "{\"symbols\":[\"corporation\",\"&attrs\"],\"statements\":[[5,\"small-box\",[[13,2]],[[\"@iconname\",\"@title\"],[\"industry\",\"Virksomheder\"]],{\"statements\":[[0,\"\\n  \"],[5,\"data-power-select\",[],[[\"@modelName\",\"@searchEnabled\",\"@queryKey\",\"@loadingMessage\",\"@searchMessage\",\"@noMatchesMessage\",\"@onChange\",\"@onInput\",\"@selected\",\"@placeholder\",\"@triggerClass\",\"@dropdownClass\",\"@allowClear\"],[\"virksomheder\",true,\"searchWord\",\"Indlæser...\",\"Indtast virksomhedens navn i søgefeltet\",\"Ingen virksomheder opfylder søgekriteriet\",[28,\"action\",[[23,0,[]],\"onChosenCorporation\"],null],[28,\"action\",[[23,0,[]],\"checkLength\"],null],[22,\"selectedCorporation\"],\"Alle virksomheder\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"filter-corporation-container\"],[8],[0,\"\\n      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"filter-corporation-sub-container\"],[8],[0,\"\\n        \"],[1,[23,1,[\"address\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n  \\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-virksomhed.hbs"
    }
  });

  _exports.default = _default;
});