define("job-ag/components/infoboks-stilling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    alt: false,
    visAlt: Ember.computed('alt', function () {
      return this.alt;
    }),
    actions: {
      toogleAlt() {
        Ember.set(this, "alt", !this.alt);
      }

    }
  });

  _exports.default = _default;
});