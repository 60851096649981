define("job-ag/controllers/startsider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchWord'],
    searchWord: null,
    visningsType: 0,
    params: Ember.computed('searchWord', 'visningsType', function () {
      let searchWord = Ember.get(this, 'searchWord');
      let visningsType = Ember.get(this, 'visningsType');
      return {
        api: 'startside',
        searchWord: searchWord,
        visningsType: visningsType
      };
    }),
    actions: {
      setSearchValue(searchWord) {
        Ember.set(this, 'searchWord', searchWord);
      }

    }
  });

  _exports.default = _default;
});