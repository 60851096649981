define("job-ag/services/export-data", ["exports", "job-ag/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    async requestData(data, id, name, accessToken, isPDF) {
      const url = `${_environment.default.APP.host}/${_environment.default.APP.namespace}/dataexports`;

      try {
        const reponse = await (0, _fetch.default)(url, {
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/vnd.api+json",
            "Authorization": `Bearer ${accessToken}`
          }
        });
        return generateFileFromResponse(reponse, name);
      } catch (error) {
        return Promise.reject('getJSON: `' + url + '` failed with error: ' + error);
      }
    }

  });

  _exports.default = _default;

  function generateFileFromResponse(response, filename) {
    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        resolve(true);
        var type = response.headers.get("content-type");
        var blob = typeof File === 'function' ? new File([response], filename, {
          type: type
        }) : new Blob([response], {
          type: type
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a"); // safari doesn't support this yet

            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      } else {
        reject(new Error('getJSON: `' + response.url + '` failed with status: [' + response.status + ']'));
      }
    });
  }
});