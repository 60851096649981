define("job-ag/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    load() {
      let userId = Ember.get(this, 'session.data.authenticated.userName');

      if (!Ember.isEmpty(userId)) {
        let fullName = Ember.get(this, 'session.data.authenticated.fullName');
        let role = Ember.get(this, 'session.data.authenticated.userRole');
        let start = Ember.get(this, 'session.data.authenticated.startSide');
        let valgtkoe = Ember.get(this, 'session.data.authenticated.valgtkoe');
        let valgtkoegruppe = Ember.get(this, 'session.data.authenticated.valgtkoegruppe');
        let email = Ember.get(this, 'session.data.authenticated.email');
        let markering = Ember.get(this, 'session.data.authenticated.markering');
        let currentUser = this.store.createRecord('user');
        Ember.set(currentUser, 'userRole', role);
        Ember.set(currentUser, 'userName', userId);
        Ember.set(currentUser, 'fullName', fullName);
        Ember.set(currentUser, 'startSide', start);
        Ember.set(currentUser, 'email', email); //currentUser.userRole 		= role;
        //currentUser.userName	 	= userId;
        //currentUser.fullName 		= fullName;
        //currentUser.startSide = start;

        Ember.set(currentUser, 'valgtkoe', valgtkoe);
        Ember.set(currentUser, 'valgtkoegruppe', valgtkoegruppe);
        Ember.set(currentUser, 'markering', markering); //currentUser.valgtkoe		= valgtkoe;
        //currentUser.valgtkoegruppe = valgtkoegruppe;
        //currentUser.email 			= email;
        //currentUser.markering = markering;

        Ember.set(currentUser, 'isAdministrator', role === "Administrator");
        Ember.set(currentUser, 'isSuperuser', role === "Superbruger" || role === "Administrator"); //currentUser.isAdministrator = role === "Administrator";
        //currentUser.isSuperuser 	= (role === "Superbruger") || (role === "Administrator");

        Ember.set(this, 'user', currentUser);
      } else {
        return Ember.RSVP.resolve();
      }
    }

  });

  _exports.default = _default;
});