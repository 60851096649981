define("job-ag/controllers/login", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let findTokenValue = function (settings, token) {
    let result;
    const foundSettings = settings.filterBy('token', token);

    if (foundSettings && foundSettings.length > 0) {
      const setting = foundSettings[0];

      if (setting) {
        result = Ember.get(setting, 'value');
      }
    }

    return result;
  };

  var _default = Ember.Controller.extend({
    /* metrics: service(),*/
    session: Ember.inject.service(),
    thisYear: new Date().getFullYear(),
    appTitel: _environment.default.AppTitle,
    errorMessage: '',
    actions: {
      authenticate() {
        let {
          identification,
          password
        } = this; //set(this, 'metrics.context.userName', identification );

        let that = this;
        this.session.authenticate('authenticator:oauth2', identification, password).then(function (data) {
          Ember.get(that, 'store').findAll('setting').then(function (allsettings) {
            /*
            			let googleAnalyticsKey = findTokenValue(allsettings, 'googleAnalyticsKey');		
            
            			if (googleAnalyticsKey)
            			{
            				const metrics = get(that, 'metrics');
            
            				metrics.activateAdapters([
            				{
            				name: 'GoogleAnalytics',
            				environments: ['development', 'production'],
            				config: {
            					id: googleAnalyticsKey,
            					// Use `analytics_debug.js` in development
            					debug: false, 
            					//ENV.environment === 'development',
            					// Use verbose tracing of GA events
            					trace: false, 
            					//ENV.environment === 'development',
            					// Ensure development env hits aren't sent to GA
            					//sendHitTask: environment !== 'development'
            				}
            			}
            			]);
            
            			} */
          }).catch(reason => {
            Ember.Logger.log(reason);
            this.set('errorMessage', reason.error || reason);
          });
        });
      }

    }
  });

  _exports.default = _default;
});