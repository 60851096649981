define("job-ag/components/card-diagram", ["exports", "fremmoedesystem/config/environment", "moment", "d3", "ember-concurrency"], function (_exports, _environment, _moment, _d, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var request = function (_this, id, name) {
    let data = Ember.get(_this, 'params');
    const url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/datareports';
    let version = _environment.default.APP.version;

    if (!version) {
      version = 'Ikke defineret';
    }

    let {
      access_token
    } = _this.get('session.data.authenticated');

    return new Promise(function (resolve, reject) {
      // _this
      //   .get('session')
      //  .authorize('authorizer:oauth2', (headerName, headerValue) => {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = 'arraybuffer'; //   xhr.setRequestHeader(headerName, headerValue);

        if (Ember.isPresent(access_token)) {
          xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
        }

        xhr.onload = function () {
          if (this.status === 200) {
            resolve(true);
            var filename = name;
            var type = xhr.getResponseHeader('Content-Type');
            var blob = typeof File === 'function' ? new File([this.response], filename, {
              type: type
            }) : new Blob([this.response], {
              type: type
            });

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
              window.navigator.msSaveBlob(blob, filename);
            } else {
              var URL = window.URL || window.webkitURL;
              var downloadUrl = URL.createObjectURL(blob);

              if (filename) {
                // use HTML5 a[download] attribute to specify filename
                var a = document.createElement('a'); // safari doesn't support this yet

                if (typeof a.download === 'undefined') {
                  window.location = downloadUrl;
                } else {
                  a.href = downloadUrl;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                }
              } else {
                window.location = downloadUrl;
              }

              setTimeout(function () {
                URL.revokeObjectURL(downloadUrl);
              }, 100); // cleanup
            }
          } else {
            reject(new Error('getJSON: `' + url + '` failed with status: [' + this.status + ']'));
          }
        };

        xhr.setRequestHeader('Content-type', 'application/vnd.api+json'); // console.log("presend");

        xhr.send(data);
      } catch (err) {//	console.log("error: " + err);
      } // });

    });
  };

  let dataArray = [{
    time: _d.default.time.format('%Y-%m-%d').parse('2017-05-15'),
    label: 'Lovligt',
    value: 173
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-05-15'),
    label: 'Ulovligt',
    value: 45
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-06-15'),
    label: 'Lovligt',
    value: 153
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-06-15'),
    label: 'Ulovligt',
    value: 15
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-07-16'),
    label: 'Lovligt',
    value: 143
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-07-16'),
    label: 'Ulovligt',
    value: 85
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-08-16'),
    label: 'Lovligt',
    value: 423
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-08-16'),
    label: 'Ulovligt',
    value: 901
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-09-16'),
    label: 'Lovligt',
    value: 573
  }, {
    time: _d.default.time.format('%Y-%m-%d').parse('2017-09-16'),
    label: 'Ulovligt',
    value: 395
  }];

  var _default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    config: Ember.inject.service(),
    formattedTime: _d.default.time.format('%d/%m/%Y'),
    formatTime: _d.default.time.format('%d/%m/%Y'),
    isPdf: false,
    valgtTilbudssted: null,
    valgtBorger: null,
    valgtAktivitet: null,
    valgtStartdato: null,
    valgtSlutdato: null,
    selectedInterval: 'D',
    selectedSeedColor: '#1ABB9C',
    fetchData: (0, _emberConcurrency.task)(function* () {
      let that = this;
      let rapportIDStr = Ember.get(this, 'rapport');
      let rapportID = parseInt(rapportIDStr, 10);
      const valgtTilbudssted = Ember.get(this, 'valgtTilbudssted');
      const valgtTilbudsstedId = valgtTilbudssted ? valgtTilbudssted.id : -1;
      const valgtBorger = Ember.get(this, 'valgtBorger');
      const valgtBorgerId = valgtBorger ? valgtBorger.id : -1;
      const valgtAktivitet = Ember.get(this, 'valgtAktivitet');
      const valgtAktivitetId = valgtAktivitet ? valgtAktivitet.id : -1;
      let valgtStartdato = Ember.get(this, 'valgtStartdato');
      valgtStartdato = (0, _moment.default)(valgtStartdato, 'DD-MM-YYYY');
      let valgtSlutdato = Ember.get(this, 'valgtSlutdato');
      valgtSlutdato = (0, _moment.default)(valgtSlutdato, 'DD-MM-YYYY');
      let link = that.get('config.APP.namespaceRoot') + '/' + that.get('config.APP.namespace') + '/datareports/chartdata';
      return yield that.ajax.request(link, {
        dataType: 'text',
        contentType: 'application/vnd.api+json',
        method: 'POST',
        cache: false,
        data: {
          data: {
            type: 'datareport',
            attributes: {
              'rapport-id': rapportID,
              'valgt-tilbudssted': valgtTilbudsstedId,
              'valgt-borger': valgtBorgerId,
              'valgt-aktivitet': valgtAktivitetId,
              version: _environment.default.APP.version,
              'valgt-startdato': valgtStartdato.format('YYYY-MM-DDTHH:mm:ss'),
              'valgt-slutdato': valgtSlutdato.format('YYYY-MM-DDTHH:mm:ss')
            }
          }
        }
      }).then(datatext => {
        if (datatext) {
          var json = JSON.parse(datatext);

          if (json) {
            var items = json.data;

            if (items) {
              var chartdata = items.map(function (item) {
                var time = _d.default.time.format('%Y-%m-%dT%H:%M:%S').parse(item.attributes.time);

                return {
                  time: time,
                  label: item.attributes.label,
                  value: item.attributes.value
                };
              });
              return chartdata;
            }
          }
        }

        return [];
      }).catch(function ()
      /*arg1, arg2*/
      {
        //	debugger;
        return [];
      });
    }),
    linedata: Ember.computed('params', function () {
      let demo = false;

      if (demo) {
        return dataArray;
      } else {
        return this.get('fetchData').perform();
      }
    }),
    params: Ember.computed('isPdf', 'valgtTilbudssted', 'valgtBorger', 'valgtAktivitet', 'valgtStartdato', 'valgtSlutdato', function () {
      const valgtTilbudssted = Ember.get(this, 'valgtTilbudssted');
      const valgtTilbudsstedId = valgtTilbudssted ? valgtTilbudssted.id : -1;
      const valgtBorger = Ember.get(this, 'valgtBorger');
      const valgtBorgerId = valgtBorger ? valgtBorger.id : -1;
      const valgtAktivitet = Ember.get(this, 'valgtAktivitet');
      const valgtAktivitetId = valgtAktivitet ? valgtAktivitet.id : -1;
      const valgtStartdato = Ember.get(this, 'valgtStartdato');
      const valgtSlutdato = Ember.get(this, 'valgtSlutdato');
      let data = '{ "data" : {	"type": "dataexport","attributes": {';
      data += '"id" : 1\n';
      let isPdf = Ember.get(this, 'isPdf');
      data += ',"pdf" : ' + isPdf + '\n';
      let rapportID = Ember.get(this, 'rapport.id');

      if (rapportID) {
        data += ',"rapport-id" : ' + rapportID + '\n';
      }

      if (valgtTilbudssted) {
        data += ',"valgt-tilbudssted" : "' + valgtTilbudsstedId + '"\n';
      }

      if (valgtBorger) {
        data += ',"valgt-borger" : ' + valgtBorgerId + '\n';
      }

      if (valgtAktivitet) {
        data += ',"valgt-aktivitet" : ' + valgtAktivitetId + '\n';
      }

      if (_environment.default.APP.version) {
        data += ',"version"  : "' + _environment.default.APP.version + '"\n';
      }

      if (valgtStartdato) {
        data += ',"valgt-startdato" : "' + (0, _moment.default)(valgtStartdato).format('YYYY-MM-DDTHH:mm:ss') + '"\n';
      }

      if (valgtSlutdato) {
        data += ',"valgt-slutdato"  : "' + (0, _moment.default)(valgtSlutdato).format('YYYY-MM-DDTHH:mm:ss') + '"\n';
      }

      data += ' }}}';
      return data;
    }),
    actions: {
      gemPDF(id) {
        if (id) {
          Ember.set(this, 'isPdf', true);
          return request(this, id, 'rapport.pdf');
        }
      },

      gemExcel(id) {
        if (id) {
          Ember.set(this, 'isPdf', false);
          return request(this, id, 'rapport.xlsx');
        }
      }

    }
  });

  _exports.default = _default;
});