define("job-ag/validations/kandidat", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    personCivilRegistrationIdentifierType: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 10,
      max: 10
    })]
  };
  _exports.default = _default;
});