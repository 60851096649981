define("job-ag/routes/pladser", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "job-ag/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      console.log("ROUTER");
      let iframeLink = _environment.default.namespaceRoot; //	iframeLink = iframeLink + '/App/kontrolpanel/.aspx';

      return 'https://e09def3a.internetfirmaet.dk/kontrolpanel/virksomheder/demo/virksomheder4.aspx'; //var appointments = this.get('store').findAll('appointment');
      //return appointments;
    }

  });

  _exports.default = _default;
});