define("job-ag/controllers/joborder/new", ["exports", "jquery", "moment", "ember-concurrency", "job-ag/validations/joborder", "job-ag/config/environment"], function (_exports, _jquery, _moment, _emberConcurrency, _joborder, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let today = (0, _moment.default)();
  const DEBOUNCE_MS = 250;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    loading: false,
    JoborderValidations: _joborder.default,
    ajax: Ember.inject.service(),
    visSearchlist: true,
    valgtVirksomhed: null,
    harValgtVirksomhed: Ember.computed.notEmpty('valgtVirksomhed'),
    valgtVirksomhedsdata: 'Der er endnu ikke valgt nogen virksomhed. <br><br>Søg efter virksomheden via søgefeltet og vælg derefter virksomheden i søgelisten.',
    selected: today,
    companySearch: '',
    searchResultList: null,
    queryParams: ['searchWord'],
    searchWord: null,
    visningsType: 0,
    params: Ember.computed('searchWord', 'visningsType', 'kommune', function () {
      //debugger;
      let kommuneIdentifier = Ember.get(this, 'kommune.identifier');
      let searchWord = Ember.get(this, 'searchWord');
      let visningsType = Ember.get(this, 'visningsType');
      return {
        searchWord: searchWord,
        visningsType: visningsType,
        kommuneIdentifier: kommuneIdentifier
      };
    }),
    submit: (0, _emberConcurrency.task)(function* (model) {
      yield model.save(); // ... more code to show success messages etc.
    }),
    getJSON: (0, _emberConcurrency.task)(function* (url, data) {
      let xhr;

      try {
        xhr = _jquery.default.getJSON(url, data);
        return yield xhr.promise();
      } finally {
        xhr.abort;
      }
    }),
    ajaxSearch: (0, _emberConcurrency.task)(function* (kommuneIdentifier, key) {
      if (Ember.isBlank(key)) {
        return [];
      }

      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      let that = this; // debugger;

      var data = {
        search: encodeURIComponent(key),
        bruger: '',
        postnumre: '',
        businessformatbusinessformatcode: -2,
        antalansattecheckboxlist: -2,
        BusinessFormatBusinessFormatCode: -2,
        virksomhedsformkoder: -2,
        AddressOfficialMunicipalityCode: kommuneIdentifier,
        juridiske: -1,
        harhistorik: -1,
        arkiverede: -1,
        etableretfra: 'Etableret%20fra',
        etablerettil: 'Etableret%20til',
        jobaabningerstatus: -1,
        kontaktpersonnavn: '',
        kontaktpersontelefon: '',
        kontaktpersonemail: '',
        samtaleraktivefra: '',
        samtaleraktivetil: '',
        samtalerikkeaktivefra: '',
        samtalerikkeaktivetil: '',
        samtalerstatus: -1,
        samtaleransvarlig: -1,
        samtalerkontakt: -1,
        kampagnebesoegt: -1,
        kampagneharhaft: -1,
        kampagnetype: -1,
        virksomhedstilbudaktivefra: '',
        virksomhedstilbudaktivetil: '',
        virksomhedstilbudikkeaktivefra: '',
        virksomhedstilbudikkeaktivetil: '',
        virksomhedstilbudmaalgruppe: -1,
        branchegruppe: 'undefined',
        branche: 'undefined',
        nybranchegruppe: -1,
        nybranchemellemgruppe: -1,
        nybranchenavn: -1,
        sortname: 'name',
        side: 0
      };
      let url = 'https://visag.dk/v1/getCompanies.ashx';
      let response = yield this.getJSON.perform(url, data);
      return response;
    }).restartable(),
    actions: {
      updateList() {
        console.log('updateList');
        this.send('search');
      },

      focus() {
        Ember.set(this, 'valgtVirksomhed', null);
        Ember.set(this, 'visSearchlist', true);
      },

      chooseCompany(item) {
        if (item && item.aspect && item.aspect.item) {
          Ember.set(this, 'valgtVirksomhed', item.aspect.item);
          Ember.set(this, 'visSearchlist', false);
          (0, _jquery.default)('.searchField').blur();
        }
      },

      preventSubmit(event) {
        if (event.key === "Enter") {
          event.preventDefault();
          return false;
        }
      },

      setSearchValue(searchWord) {
        Ember.set(this, 'searchWord', searchWord);
      },

      search(key) {
        if (!key || key.length === 0) {
          const searchValue = Ember.get(this, 'companySearch');
          console.log(`searchValue: ${searchValue}`);
          key = searchValue;
        }

        let that = this; //	that.send('loading'); // Start loading animation

        const kommuneIdentifier = Ember.get(this, 'kommune.identifier');
        that.get('ajaxSearch').perform(kommuneIdentifier, key).then(function (data) {
          Ember.set(that, 'searchResultList', data);
          Ember.set(that, 'visSearchlist', true); //	debugger;
          //that.send('finished'); // Afslut loading animation */
        });
      },

      save(changeset) {
        const currentUser = Ember.get(this, 'currentUser');

        if (currentUser.user.isAdministrator) {
          let _this = this;

          let valgtVirksomhed = Ember.get(this, 'valgtVirksomhed');
          let newJoborder = Ember.get(this, 'model.newJoborder');
          let provider = Ember.get(this, 'store').createRecord('companyRecruitmentProviderType', {
            companyRecruitmentProviderField: newJoborder
          });
          Ember.set(provider, 'cVRnumberIdentifierField', valgtVirksomhed.cvr);
          Ember.set(provider, 'productionUnitIdentifierField', valgtVirksomhed.pnr);
          provider.save(); //Ember.set(this, 'companyRecruitmentProviderField', provider);

          Ember.set(this, 'model.newJoborder.companyRecruitmentLocationNameField', valgtVirksomhed.navn);
          Ember.set(this, 'model.newJoborder.companyRecruitmentTitleField', valgtVirksomhed.navn);
          let virksomhedsAdresse = Ember.get(this, 'store').createRecord('address-postal-type', {
            companyRecruitmentLocationAddressField: newJoborder
          });
          Ember.set(virksomhedsAdresse, 'streetNameField', valgtVirksomhed.vej);
          Ember.set(virksomhedsAdresse, 'streetBuildingIdentifierField', valgtVirksomhed.bygning);
          Ember.set(virksomhedsAdresse, 'postCodeIdentifierField', valgtVirksomhed.postnr);
          Ember.set(virksomhedsAdresse, 'districtNameField', valgtVirksomhed.district);
          virksomhedsAdresse.save();
          changeset.set('numberOfCandidates', '0');
          changeset.set('serialNumberField', '0');
          changeset.set('createdDateTimeField', new Date());
          changeset.set('companyRecruitmentStatusTypeIdentifierField', '1');
          changeset.set('companyRecruitmentRespondTypeIdentifierField', '1');
          changeset.save().then(function () {
            _this.transitionToRoute('joborder');
          }).catch(() => {
            changeset.get(this, 'model.errors').forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          });
        }
      },

      cancel() {
        this.get('model.newJoborder').destroyRecord();
        history.back();
      },

      changeYear(calendar, e) {
        let newCenter = calendar.center.clone().year(e.target.value);
        calendar.actions.changeCenter(newCenter);
      }

    }
  });

  _exports.default = _default;
});