define("job-ag/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "job-ag/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let findTokenValue = function (settings, token, indexName, valueName) {
    if (!indexName) {
      indexName = 'token';
    }

    if (!valueName) {
      valueName = 'value';
    }

    let result;
    const foundSettings = settings.filterBy(indexName, token);

    if (foundSettings && foundSettings.length > 0) {
      const setting = foundSettings[0];

      if (setting) {
        result = Ember.get(setting, valueName);
      }
    }

    return result;
  };

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    model() {
      return Ember.RSVP.hash({
        settings: this.store.findAll('setting'),
        startsider: this.store.findAll('startside')
      });
    },

    afterModel(model) {
      // Gå til startside, hvis brugeren har valgt en startside gå til denne, 
      // ellers gå til standard siden som er defineret i trimme oplysningerne.
      let brugerStartside = "";
      const startsideidstr = Ember.get(this, 'currentUser.user.startSide');
      const startsideID = parseInt(startsideidstr, 10);

      if (startsideID && startsideID != -1) {
        brugerStartside = findTokenValue(model.startsider, startsideID.toString(), 'id', 'filnavn');
      }

      const standardStartside = findTokenValue(model.settings, 'StandardStartside');

      if (brugerStartside) {
        this.transitionTo(brugerStartside);
      } else if (standardStartside) {
        this.transitionTo(standardStartside);
      }
    }

  });

  _exports.default = _default;
});