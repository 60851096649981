define("job-ag/components/infoboks-kandidater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchWord: null,
    visningsType: 0,
    params: Ember.computed('searchWord', 'visningsType', 'joborder', function () {
      let searchWord = Ember.get(this, 'searchWord');
      let visningsType = Ember.get(this, 'visningsType'); //debugger;

      let jobvorderid = Ember.get(this, 'joborder.companyRecruitmentIdentifierField');
      return {
        api: 'companyrecruitmentcandidate',
        joborderid: jobvorderid,
        searchWord: searchWord,
        visningsType: visningsType
      };
    })
  });

  _exports.default = _default;
});