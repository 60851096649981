define("job-ag/controllers/startside/new", ["exports", "job-ag/validations/startside"], function (_exports, _startside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    StartsideValidations: _startside.default,
    actions: {
      save: function (changeset) {
        let placering = changeset.get('placering');

        if (!placering) {
          changeset.set('placering', '1');
        }

        var _this = this;

        changeset.save().then(function () {
          _this.transitionToRoute('startsider');
        });
      },
      cancel: function () {
        this.model.destroyRecord();
        this.transitionToRoute('startsider');
        return false;
      }
    }
  });

  _exports.default = _default;
});