define("job-ag/components/filter-periode", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    utility: Ember.inject.service(),
    valgtStartdato: '01-01-2019',
    valgtSlutdato: '01-01-2100',
    startValues: null,
    minDate: _environment.default.APP.Startdate,

    didReceiveAttrs() {
      this._super(...arguments);

      let result = [null, null];
      let valgtStartdato = Ember.get(this, 'valgtStartdato');
      let valgtSlutdato = Ember.get(this, 'valgtSlutdato');

      if (valgtStartdato && valgtSlutdato) {
        result = [valgtStartdato, valgtSlutdato];
      }

      Ember.set(this, 'startValues', result);
    },

    actions: {
      setDateRange(datoer) {
        const {
          defaultStartDate,
          defaultEndDate
        } = this.utility.getDefaultDateRangeForDatePicker({
          splitPeriode: true
        });
        let start = defaultStartDate;
        let slut = defaultEndDate;

        if (datoer && datoer.length > 1) {
          start = datoer[0];
          slut = datoer[1];
          start.setHours(0, 0, 0);
          slut.setHours(23, 59, 59);
          Ember.set(this, 'valgtStartdato', start);
          Ember.set(this, 'valgtSlutdato', slut);
          let result = [start, slut];
          Ember.set(this, 'startValues', result);
          Ember.get(this, 'onChange')(start, slut);
        }
      },

      nulstil() {
        const {
          defaultStartDate,
          defaultEndDate
        } = this.utility.getDefaultDateRangeForDatePicker({
          splitPeriode: true
        });
        Ember.set(this, 'valgtStartdato', defaultStartDate);
        Ember.set(this, 'valgtSlutdato', defaultEndDate);
        let result = [defaultStartDate, defaultEndDate];
        this.set('startValues', result);
        Ember.get(this, 'onChange')(defaultStartDate, defaultEndDate);
      }

    }
  });

  _exports.default = _default;
});