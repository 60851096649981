define("job-ag/templates/components/listitem-organisation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Z6Z+1cP",
    "block": "{\"symbols\":[\"box\"],\"statements\":[[4,\"listitem-box\",null,[[\"footer\",\"itemtype\"],[[24,[\"buttons\"]],\"Organisation\"]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"body\"],null]],null,{\"statements\":[[4,\"if\",[[24,[\"organisation\",\"isPending\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"style\",\"width:100%;height:50px;margin-top:30px;text-align:center\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"spinner\"],[[\"spin\",\"size\"],[true,\"lg\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"left col-md-12 \",[24,[\"organisation\",\"identifier\"]]]]],[10,\"style\",\"padding-left:0;min-height:170px\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12\"],[8],[0,\"\\n          \"],[7,\"h2\",true],[10,\"class\",\"breakWord lead\"],[8],[0,\"\\n            \"],[1,[24,[\"organisation\",\"name\"]],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"footer\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"tagName\",\"classNames\",\"title\",\"route\",\"model\"],[\"button\",\"btn btn-primary btn-md\",\"Rediger organisation\",\"organisation.edit\",[24,[\"organisation\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Rediger\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"tagName\",\"classNames\",\"title\",\"route\",\"model\"],[\"button\",\"btn btn-primary btn-md btn-danger\",\"Rediger organisation\",\"organisation.delete\",[24,[\"organisation\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"ban\"],null],false],[0,\" Slet\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/listitem-organisation.hbs"
    }
  });

  _exports.default = _default;
});