define("job-ag/routes/jobordre", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      const jobID = params.joborder_id; //params.id;
      // console.log(jobID);

      if (jobID) {
        return Ember.RSVP.hash({
          joborderID: jobID,
          joborder: this.store.findRecord('joborder', jobID),
          CompanyRecruitmentTypeIdentifiers: [],
          // this.store.findAll('companyrecruitmenttypeidentifier'),
          CompanyRecruitmentStatusTypeIdentifiers: [],
          //this.store.findAll('companyrecruitmentstatustype'),
          //kidx		kandidater: this.store.query('companyrecruitmentcandidate', { joborderid: this.joborder.companyRecruitmentIdentifierField}),
          CompanyRecruitmentRespondTypeIdentifiers: [],
          //this.store.findAll('companyrecruitmentrespondtype'),
          WeeklyWorkTimeTypeIdentifiers: [],
          //this.store.findAll('weeklyworktimetype'),
          OrganisationIdentifiers: [],
          //this.store.findAll('organisationtype'),		
          JobcenterIdentifiers: [] //this.store.findAll('jobcenter')				

        });
      }
    }

  });

  _exports.default = _default;
});