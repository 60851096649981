define("job-ag/templates/components/range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/zpGQC1U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",false],[12,\"class\",\"range-original\"],[12,\"type\",\"range\"],[3,\"bind-attr\",null,[[\"min\",\"max\",\"step\",\"value\"],[[24,[\"min\"]],[24,[\"max\"]],[24,[\"step\"]],[24,[\"value\"]]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/range-input.hbs"
    }
  });

  _exports.default = _default;
});