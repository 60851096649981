define("job-ag/templates/pladser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FiM6sWoo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Jobåbninger\"],[[\"_deprecate\"],[\"/home/bent/Projekter/visag-client/tmp/broccoli_persistent_filtertemplate_compiler-input_base_path-9Xf4dvoc.tmp/job-ag/templates/pladser.hbs\"]]],false],[0,\"\\n\"],[7,\"iframe\",true],[10,\"class\",\"subpagePanel\"],[10,\"id\",\"Iframe\"],[11,\"src\",[29,[[22,\"model\"]]]],[10,\"onload\",\"this.style.height=this.contentWindow.document.body.scrollHeight + 'px';\"],[8],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/pladser.hbs"
    }
  });

  _exports.default = _default;
});