define("job-ag/models/caseworker", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    fornavn: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    mellemnavn: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    efternavn: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    fullname: Ember.computed('fornavn', 'mellemnavn', 'efternavn', function () {
      const firstname = Ember.get(this, 'fornavn') || '';
      const middlename = Ember.get(this, 'mellemnavn') || '';
      const lastname = Ember.get(this, 'efternavn') || '';

      if (!middlename && middlename === "") {
        return `${firstname} ${lastname}`;
      } else {
        return `${firstname} ${middlename} ${lastname}`;
      }
    })
  });

  _exports.default = _default;
});