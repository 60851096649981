define("job-ag/components/button-async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "button",
    attributeBindings: ["type"],
    type: "button",
    _isFulfilled: false,
    _isRejected: false,
    _isPending: false,
    title: "What",
    activePromise: Ember.computed("_isFulfilled", "_isRejected", "_isPending", function () {
      return {
        isPending: this._isPending,
        isRejected: this._isRejected,
        isFulfilled: this._isFulfilled
      };
    }),

    async click(event) {
      event.preventDefault();
      Ember.set(this, "_isPending", true);

      try {
        await this.action();
        Ember.set(this, "_isPending", false);
        Ember.set(this, "_isFulfilled", true);
      } catch (error) {
        Ember.set(this, "_isPending", false);
        Ember.set(this, "_isRejected", true);
      }
    },

    actions: {}
  });

  _exports.default = _default;
});