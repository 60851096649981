define("job-ag/models/graph", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    // id: DS.attr('number'),
    values: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    colors: _emberData.default.attr()
  });

  _exports.default = _default;
});