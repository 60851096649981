define("job-ag/templates/components/infoboks-funktioner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KT/iCh37",
    "block": "{\"symbols\":[],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\"],[\"wrench\",\"Funktioner\"]],{\"statements\":[[0,\"\\n\\t\"],[7,\"ul\",true],[10,\"class\",\"toolboxlist col-md-6 col-sm-12 col-xs-12\"],[8],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-print\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"onclick\",\"print()\"],[8],[0,\"Print joborder\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-file-pdf-o\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Gem PDF rapport\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-send\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Send PDF rapport\"],[9],[0,\" \"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"ul\",true],[10,\"class\",\"toolboxlist col-md-6 col-sm-12 col-xs-12\"],[8],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-print\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Print kandidat CVer\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-file-pdf-o\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Gem kandidat CVer\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"li\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-send\"],[8],[9],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"Send kandidat CVer\"],[9],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/infoboks-funktioner.hbs"
    }
  });

  _exports.default = _default;
});