define("job-ag/components/filter-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchWord: null,
    actions: {
      change(text) {
        Ember.set(this, 'searchText', text);
        this.onChange(text);
      }

    }
  });

  _exports.default = _default;
});