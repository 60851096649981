define("job-ag/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    moment: Ember.inject.service(),

    beforeModel() {
      this.moment.setLocale('da');
      return this._loadCurrentUser();
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    _loadCurrentUser() {
      this.currentUser.load();
    }

  });

  _exports.default = _default;
});