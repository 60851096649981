define("job-ag/templates/settings/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yRbQP0kI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"onSubmit\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\"],[\"Slet trimme\",[28,\"action\",[[23,0,[]],\"delete\"],null],\"Luk\",[28,\"action\",[[23,0,[]],\"cancel\"],null],\"true\",\"Slet trimme\"]],{\"statements\":[[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Vil du virkelig slette '\"],[1,[24,[\"model\",\"title\"]],false],[0,\"'?\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/settings/delete.hbs"
    }
  });

  _exports.default = _default;
});