define("job-ag/templates/components/listitem-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuEH+P5M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"well profile_view\"],[10,\"style\",\"width:100%\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 ingenPadding\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"data-test-cardtype\",\"\"],[10,\"class\",\"brief\"],[8],[0,\"\\n        \"],[1,[22,\"itemtype\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"left col-md-12\"],[10,\"style\",\"padding-left:0; min-height:100px\"],[8],[0,\"\\n      \"],[14,1,[\"body\"]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"footer\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"data-test-buttons\",\"\"],[10,\"class\",\"col-md-12 bottom hidden-print\"],[10,\"style\",\"padding:0 5px 10px 10px\"],[8],[0,\"\\n      \"],[14,1,[\"footer\"]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/listitem-box.hbs"
    }
  });

  _exports.default = _default;
});