define("job-ag/templates/components/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3Sta0S9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-data-component-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"iconname\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"no-data-component\"],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[[22,\"iconname\"],\"fas\"]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/no-data.hbs"
    }
  });

  _exports.default = _default;
});