define("job-ag/templates/components/footer-jobag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "szw7gLiM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://internetfirmaet.dk\"],[10,\"class\",\"site_title\"],[10,\"style\",\"padding-left:0;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"alt\",\"internetfirmaets logo\"],[10,\"style\",\"width:32px;height:32px;\"],[10,\"target\",\"_blank\"],[11,\"src\",[29,[[22,\"rootURL\"],\"images/favicon.png\"]]],[8],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"style\",\"color:rgb(115, 135, 156);\"],[8],[0,\"\\n\\t\\t\\t\\tinternetfirmaet\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-3\"],[8],[0,\"\\n\\t\\t\"],[7,\"strong\",true],[8],[0,\"\\n\\t\\t\\tVisPlads V5\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t© internetfirmaet 2017 -\\n\\t\\t\"],[1,[28,\"moment-format\",[[28,\"now\",null,null],\"YYYY\"],null],false],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://internetfirmaet.dk\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n\\t\\t\\tinternetfirmaet.dk\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"mailto:kontakt@internetfirmaet.dk\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n\\t\\t\\tKontakt os\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-md-5\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"link-list\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/footer-jobag.hbs"
    }
  });

  _exports.default = _default;
});