define("job-ag/controllers/users/edit", ["exports", "job-ag/validations/account"], function (_exports, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    AccountValidations: _account.default,
    actions: {
      save(changeset) {
        let _this = this;

        changeset.save().then(function () {
          _this.transitionToRoute('users');
        });
      },

      cancel() {
        this.transitionToRoute('users');
        return false;
      }

    }
  });

  _exports.default = _default;
});