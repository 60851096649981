define("job-ag/components/footer-jobag", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['hidden-print'],
    rootURL: _environment.default.rootURL
  });

  _exports.default = _default;
});