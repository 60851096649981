define("job-ag/templates/demo/joborder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LngJXbNh",
    "block": "{\"symbols\":[],\"statements\":[[5,\"joborder-redigering\",[],[[\"@model\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/demo/joborder.hbs"
    }
  });

  _exports.default = _default;
});