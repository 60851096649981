define("job-ag/templates/components/filter-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hYP/L7Wz",
    "block": "{\"symbols\":[],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\",\"@contentClass\"],[\"search\",\"Søgning\",[23,0,[\"contentClass\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"key-up\",\"placeholder\"],[\"form-control\",[24,[\"searchWord\"]],[28,\"action\",[[23,0,[]],\"change\"],null],\"Søg...\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-search.hbs"
    }
  });

  _exports.default = _default;
});