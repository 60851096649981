define("job-ag/controllers/jobordre/borger/edit", ["exports", "job-ag/validations/kandidat"], function (_exports, _kandidat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    KandidatValidations: _kandidat.default,
    actions: {
      save(changeset) {
        let _this = this;
        /*
        	let link = changeset.get('link');
        	link = link.toLowerCase(); */

        /*
        	if (!link.startsWith('http')) {
        		if (!link.startsWith(':')) {
        			while (!link.startsWith('//')) {
        			link = `/${link}`;
        			}
        			link = `:${link}`;
        		}
        		link = `http${link}`;
        	}
        	changeset.set('link', link);
        
        	changeset.set('logo', '');
        	changeset.set('database', '');*/


        changeset.set('personNameStructure', {});
        changeset.save().then(function () {
          // personNameStructure
          history.back();
        });
      },

      cancel() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});