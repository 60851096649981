define("job-ag/helpers/gt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.default = void 0;

  function gt(params
  /*, hash*/
  ) {
    if (params[0] > params[1]) {
      return params.fn(this);
    }

    return params;
  }

  var _default = Ember.Helper.helper(gt);

  _exports.default = _default;
});