define("job-ag/templates/jobordre/borger/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9TJT9nOC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"backdropClose\",\"onSubmit\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\"],[\"Fjern kandidat\",false,[28,\"action\",[[23,0,[]],\"delete\"],null],\"Luk\",[28,\"action\",[[23,0,[]],\"cancel\"],null],\"true\",\"Fjern kandidat\"]],{\"statements\":[[0,\"\\n\"],[7,\"p\",true],[8],[0,\"Vil du virkelig fjerne kandidaten '\"],[1,[24,[\"model\",\"PersonName\"]],false],[0,\"' fra jobordren?\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/jobordre/borger/delete.hbs"
    }
  });

  _exports.default = _default;
});