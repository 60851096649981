define("job-ag/components/g-map", ["exports", "ember-g-map/components/g-map"], function (_exports, _gMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _gMap.default;
    }
  });
});