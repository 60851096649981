define("job-ag/components/version-info", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: 'nav navbar-nav versioninfo',
    buildDate: _environment.default.APP.buildDate
  });

  _exports.default = _default;
});