define("job-ag/templates/components/infoboks-eksport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjw6MCiK",
    "block": "{\"symbols\":[\"component\",\"state\",\"&attrs\"],\"statements\":[[5,\"small-box\",[[12,\"class\",\"w-75\"],[13,3]],[[\"@iconname\",\"@title\"],[\"exchange-alt\",\"Eksport\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n\"],[4,\"async-button\",null,[[\"classNames\",\"action\"],[\"btn btn-primary btn-md\",[28,\"action\",[[23,0,[]],\"gemExcel\"],null]]],{\"statements\":[[4,\"if\",[[23,2,[\"isDefault\"]]],null,{\"statements\":[[0,\"        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"file-excel\",\"far\"]],{\"statements\":[],\"parameters\":[]}],[0,\" Gem Excel\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,2,[\"isPending\"]]],null,{\"statements\":[[0,\"        Henter data...\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,2,[\"isFulfilled\"]]],null,{\"statements\":[[0,\"        Data hentet\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,2,[\"isRejected\"]]],null,{\"statements\":[[0,\"        Fejl under hentning!\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/infoboks-eksport.hbs"
    }
  });

  _exports.default = _default;
});