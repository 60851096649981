define("job-ag/routes/jobordre/borger/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return Ember.RSVP.hash({
        Candidate: this.store.findRecord('companyrecruitmentcandidate', params.borger_id),
        CandidateRecruitmentStatusTypes: this.store.findAll('candidaterecruitmentstatustype')
      });
    }

  });

  _exports.default = _default;
});