define("job-ag/templates/components/filter-team", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eBzClLw8",
    "block": "{\"symbols\":[\"team\",\"&attrs\"],\"statements\":[[5,\"small-box\",[[13,2]],[[\"@iconname\",\"@title\",\"@contentClass\"],[\"users\",\"Team\",[23,0,[\"contentClass\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"data-power-select\",[],[[\"@modelName\",\"@searchEnabled\",\"@queryKey\",\"@loadingMessage\",\"@searchMessage\",\"@noMatchesMessage\",\"@onChange\",\"@onInput\",\"@selected\",\"@placeholder\",\"@triggerClass\",\"@dropdownClass\",\"@allowClear\"],[\"team\",true,\"searchWord\",\"Indlæser...\",\"Indtast teamets navn i søgefeltet\",\"Intet team opfylder søgekriteriet\",[28,\"action\",[[23,0,[]],\"onChosenTeam\"],null],[28,\"action\",[[23,0,[]],\"checkLength\"],null],[22,\"selectedTeam\"],\"Alle teams\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"option-container\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-team.hbs"
    }
  });

  _exports.default = _default;
});