define("job-ag/routes/demo/joborder", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "job-ag/mixins/loading-slider"], function (_exports, _authenticatedRouteMixin, _loadingSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingSlider.default, {
    model() {
      return Ember.RSVP.hash({
        svartyper: this.store.findAll('companyrecruitmentrespondtype'),
        henvendelsestyper: this.store.findAll('companyrecruitmenttypeidentifier'),
        weeklyworktimetyper: this.store.findAll('weeklyworktimetype'),
        aktivitetetstyper: this.store.findAll('aktivitetstype'),
        newJoborder: this.store.createRecord('joborder', {
          companyRecruitmentStartDateField: new Date(),
          companyRecruitmentValidToDateField: new Date(),
          wantsEmailNotificationField: true,
          companyRecruitmentTypeIdentifierField: 1,
          companyRecruitmentTitleField: '',
          companyRecruitmentJobDescriptionField: '',
          companyRecruitmentCommentField: '',
          weeklyWorkTimeTypeIdentifierField: 3,
          occupationIdField: '',
          hoursPerWeekField: '',
          internalAuthorityCommentField: '',
          RecruitmentCaseWorkerAllowed: false
        }),
        settings: [] //this.store.findAll('setting'),

      });
    }

  });

  _exports.default = _default;
});