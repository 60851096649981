define("job-ag/templates/components/slet-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lp+gIGjn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"onSubmit\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\",\"class\",\"data-test-slet-modal\"],[[24,[\"title\"]],[24,[\"delete\"]],\"Luk\",[24,[\"cancel\"]],\"true\",[24,[\"title\"]],\"alwaysScrollY\",true]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[1,[22,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/slet-modal.hbs"
    }
  });

  _exports.default = _default;
});