define("job-ag/templates/components/button-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2yt3eWR",
    "block": "{\"symbols\":[\"btn\"],\"statements\":[[5,\"button-async\",[],[[\"@action\",\"@classNames\"],[[28,\"action\",[[23,0,[]],\"exportClick\"],null],\"btn btn-primary btn-md\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"state\",\"isPending\"]]],null,{\"statements\":[[0,\"    Henter data...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"state\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"    Data hentet\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"state\",\"isRejected\"]]],null,{\"statements\":[[0,\"    Fejl under hentning!\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"file-excel\",\"far\"]]],[0,\" Gem Excel\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/button-export.hbs"
    }
  });

  _exports.default = _default;
});