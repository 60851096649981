define("job-ag/components/button-export", ["exports", "job-ag/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    exportService: Ember.inject.service("export-data"),
    id: 0,
    isPdf: false,
    exportFileName: "dataeksport.xlsx",
    data: Ember.computed('isPdf', 'valgtTilbudssted', 'valgtBorger', 'valgtAktivitet', 'valgtStartdato', 'valgtSlutdato', function () {
      const valgtStartdato = this.valgtStartdato;
      const valgtSlutdato = this.valgtSlutdato;
      let data = {
        data: {
          type: "dataexport",
          attributes: {
            id: 1,
            filtertext: "",
            pdf: this.isPdf //   rapportId: "0",
            //   version: "0",
            //   valgtStartDato: "0",
            //   valgtSlutData: "0"

          }
        }
      };
      let rapportId = Ember.get(this, 'rapport.id');

      if (rapportId) {
        data.data.attributes.rapportId = rapportId;
      }

      if (_environment.default.APP.version) {
        data.data.attributes.version = _environment.default.APP.version;
      }

      if (valgtStartdato) {
        data.data.attributes.valgtStartDato = (0, _moment.default)(valgtStartdato, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
      }

      if (valgtSlutdato) {
        data.data.attributes.valgtSlutdato = (0, _moment.default)(valgtSlutdato, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss');
      }

      return JSON.stringify(data);
    }),
    actions: {
      exportClick() {
        const {
          access_token
        } = Ember.get(this, 'session.data.authenticated');
        const id = Ember.get(this, "id");
        const filename = Ember.get(this, 'exportFileName');
        const data = Ember.get(this, 'data');
        return this.exportService.requestData(data, id, filename, access_token, false);
      }

    }
  });

  _exports.default = _default;
});