define("job-ag/templates/components/filter-branche", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S68S1aHc",
    "block": "{\"symbols\":[\"business\"],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\"],[\"cubes\",\"Stillinger\"]],{\"statements\":[[0,\"\\n  \"],[5,\"data-power-select\",[],[[\"@modelName\",\"@searchEnabled\",\"@queryKey\",\"@loadingMessage\",\"@searchMessage\",\"@noMatchesMessage\",\"@onChange\",\"@onInput\",\"@selected\",\"@placeholder\",\"@triggerClass\",\"@dropdownClass\",\"@allowClear\"],[\"discoams08\",true,\"searchWord\",\"Indlæser...\",\"Indtast stilling i søgefeltet\",\"Ingen stilling opfylder søgekritererne.\",[28,\"action\",[[23,0,[]],\"onChosenBusiness\"],null],[28,\"action\",[[23,0,[]],\"checkLength\"],null],[22,\"selectedBusiness\"],\"Alle stillinger\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"option-container\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-branche.hbs"
    }
  });

  _exports.default = _default;
});