define("job-ag/components/data-loader", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE = 1000;

  var extend = function (obj, src) {
    Object.keys(src).forEach(function (key) {
      obj[key] = src[key];
    });
    return obj;
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    utility: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let that = this;
      let perPage = that.attrs.perPage ? that.attrs.perPage.value : '';
      let page = that.attrs.page ? that.attrs.page.value : '';
      let paramsobject = {
        perPage: perPage,
        page: page
      };
      let kompletobject = extend(paramsobject, that.attrs.params.value);
      that.fetchData.perform(kompletobject);
    },

    fetchData: (0, _emberConcurrency.task)(function* (params) {
      // Tell subscribers that a data fetch is occuring
      this.utility.dataFetched(this.parent);
      yield (0, _emberConcurrency.timeout)(DEBOUNCE);
      let data = yield this.store.query(params.api, params);
      Ember.set(this, 'results', data);
      let total = 0;

      if (data) {
        total = data.get('meta.total');
      }

      if (this.onDataLoaded) {
        this.onDataLoaded(total);
      }

      return true;
    }).restartable()
  });

  _exports.default = _default;
});