define("job-ag/templates/components/filter-periode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4tUiUmA",
    "block": "{\"symbols\":[],\"statements\":[[5,\"small-box\",[[12,\"contentClass\",[23,0,[\"contentClass\"]]]],[[\"@iconname\",\"@title\"],[\"calendar\",\"Periode\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-group w-100 date-picker-priode-container\"],[8],[0,\"\\n    \"],[5,\"ember-flatpickr\",[],[[\"@locale\",\"@mode\",\"@nextArrow\",\"@date\",\"@dateFormat\",\"@altFormat\",\"@onChange\",\"@placeholder\",\"@prevArrow\",\"@weekNumbers\",\"@classNames\"],[\"da\",\"range\",\">\",[22,\"startValues\"],\"d-m-Y\",\"Y-m-d\",[28,\"action\",[[23,0,[]],\"setDateRange\"],null],\"Vælg en periode...\",\"<\",true,\"form-control date-picker-periode\"]]],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group-append\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"title\",\"Nulstil periode\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"nulstil\"]],[8],[1,[28,\"fa-icon\",[\"times\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-periode.hbs"
    }
  });

  _exports.default = _default;
});