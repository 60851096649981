define("job-ag/controllers/demo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    utility: Ember.inject.service(),
    activeTab: "pladser",
    selectedStartDate: null,
    selectedEndDate: null,
    params: Ember.computed('selectedStartDate', 'selectedEndDate', function () {
      let selectedStartDate = Ember.get(this, 'selectedStartDate') || '';
      let selectedEndDate = Ember.get(this, 'selectedEndDate') || '';
      return {
        api: 'demo',
        startdate: selectedStartDate,
        endDate: selectedEndDate
      };
    }),
    actions: {
      setActiveTab(newId) {
        this.utility.setActiveTab(newId);
      },

      setDateRange(start, slut) {
        Ember.set(this, 'selectedStartDate', start);
        Ember.set(this, 'selectedEndDate', slut);
      }

    }
  });

  _exports.default = _default;
});