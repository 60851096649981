define("job-ag/controllers/jobordre/borger/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      delete() {
        let _this = this;

        this.model.destroyRecord().then(function () {
          _this.transitionToRoute('jobordre');
        });
      },

      cancel() {
        history.back();
        return false;
      }

    }
  });

  _exports.default = _default;
});