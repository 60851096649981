define("job-ag/components/virksomheds-loader", ["exports", "ember-concurrency", "jquery", "ember-data"], function (_exports, _emberConcurrency, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE = 1000;

  var extend = function (obj, src) {
    Object.keys(src).forEach(function (key) {
      obj[key] = src[key];
    });
    return obj;
  };

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.results = [];
    },

    didReceiveAttrs() {
      console.log('virksomhedsloader: didReceiveAttrs');

      this._super(...arguments);

      let that = this;
      let perPage = that.attrs.perPage ? that.attrs.perPage.value : '';
      let page = that.attrs.page ? that.attrs.page.value : ''; // ...that.attrs.params.value,

      let paramsobject = {
        perPage: perPage,
        page: page
      };
      let kompletobject = extend(paramsobject, that.attrs.params.value);
      that.fetchData.perform(kompletobject);
    },

    getJSON: (0, _emberConcurrency.task)(function* (url, data) {
      console.log('virksomheds-loader:getJSON1');
      let xhr;

      try {
        let promiseArray = _emberData.default.PromiseArray.create({
          promise: _jquery.default.getJSON(url, data)
        });

        console.log('virksomheds-loader:getJSON2');
        return yield promiseArray.promise;
      } catch (err) {
        console.log('getJSON-Error: ' + err);
      } finally {//	xhr.abort;
      }
    }),
    fetchData: (0, _emberConcurrency.task)(function* (params) {
      console.log('virksomhedsloader: fetchData');
      var data = {
        search: encodeURIComponent(params.searchWord),
        bruger: '',
        postnumre: '',
        businessformatbusinessformatcode: -2,
        antalansattecheckboxlist: -2,
        BusinessFormatBusinessFormatCode: -2,
        virksomhedsformkoder: -2,
        AddressOfficialMunicipalityCode: params.kommuneIdentifier,
        juridiske: -1,
        harhistorik: -1,
        arkiverede: -1,
        etableretfra: 'Etableret%20fra',
        etablerettil: 'Etableret%20til',
        jobaabningerstatus: -1,
        kontaktpersonnavn: '',
        kontaktpersontelefon: '',
        kontaktpersonemail: '',
        samtaleraktivefra: '',
        samtaleraktivetil: '',
        samtalerikkeaktivefra: '',
        samtalerikkeaktivetil: '',
        samtalerstatus: -1,
        samtaleransvarlig: -1,
        samtalerkontakt: -1,
        kampagnebesoegt: -1,
        kampagneharhaft: -1,
        kampagnetype: -1,
        virksomhedstilbudaktivefra: '',
        virksomhedstilbudaktivetil: '',
        virksomhedstilbudikkeaktivefra: '',
        virksomhedstilbudikkeaktivetil: '',
        virksomhedstilbudmaalgruppe: -1,
        branchegruppe: 'undefined',
        branche: 'undefined',
        nybranchegruppe: -1,
        nybranchemellemgruppe: -1,
        nybranchenavn: -1,
        sortname: 'name',
        side: 0
      };
      yield (0, _emberConcurrency.timeout)(DEBOUNCE);
      let that = this;
      let url = 'https://visag.dk/v1/getCompanies.ashx';
      let response = this.getJSON.perform(url, data);
      console.log('virksomhedsloader: efter getJS	ON');
      console.log('Response: ' + response);
      Ember.set(that, 'results', response); //debugger;

      return response;
    }).restartable()
  });

  _exports.default = _default;
});