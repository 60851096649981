define("job-ag/controllers/settings/new", ["exports", "job-ag/validations/setting"], function (_exports, _setting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    SettingValidations: _setting.default,
    actions: {
      save(changeset) {
        let _this = this;

        changeset.save().then(function (setting) {
          // console.log('Settings - save - done');
          _this.transitionToRoute('settings');
        });
      },

      cancel() {
        this.model.destroyRecord();
        this.transitionToRoute('settings');
        return false;
      }

    }
  });

  _exports.default = _default;
});