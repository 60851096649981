define("job-ag/routes/startside/delete", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return this.store.findRecord('startsider', params.startsideid);
    }

  });

  _exports.default = _default;
});