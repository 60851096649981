define("job-ag/templates/components/virksomheds-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FsbWvnYR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"isRunning\",\"data\"],[[24,[\"results\",\"isRunning\"]],[24,[\"results\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/virksomheds-loader.hbs"
    }
  });

  _exports.default = _default;
});