define("job-ag/templates/components/title-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I97ZSNFR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"href\",\"https://internetfirmaet.dk\"],[10,\"class\",\"site_title\"],[8],[0,\"\\n\\t\\t\"],[7,\"img\",true],[10,\"alt\",\"internetfirmaet logo\"],[10,\"src\",\"/images/favicon.png\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[8],[0,\"internetfirmaet\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/title-logo.hbs"
    }
  });

  _exports.default = _default;
});