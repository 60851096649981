define("job-ag/routes/jobordre/borger/delete", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return {
        id: 1,
        PersonCivilRegistrationIdentifier: '1234561234',
        CvCustomerIdentifier: '123456789',
        CandidateComment: 'En kommentar til kandidaten.',
        CandidateUrl: 'http://www.google.com',
        CandidateRecruitmentStatusTypeIdentifier: 'Formidling tilgængelig for virksomhed',
        PersonNameStructurePersonGivenName: 'Jens',
        PersonNameStructurePersonMiddleName: '',
        PersonNameStructurePersonSurnameName: 'Jensen',
        PersonName: 'Jens Jensen'
      };
    }

  });

  _exports.default = _default;
});