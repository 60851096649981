define("job-ag/templates/demo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3kXsJDev",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-container\"],[8],[0,\"\\n  \"],[5,\"bs-tab\",[[12,\"class\",\"pl-0 pr-0\"]],[[\"@onChange\"],[[28,\"action\",[[23,0,[]],\"setActiveTab\"],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[[12,\"class\",\"\"]],[[\"@id\",\"@title\"],[\"pladser\",\"Pladser\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12 pl-0\"],[8],[0,\"\\n        \"],[5,\"aktiviteter\",[],[[\"@selectedEndDate\",\"@selectedStartDate\",\"@selectedBusiness\",\"@selectedKommune\"],[[23,0,[\"selectedEndDate\"]],[23,0,[\"selectedStartDate\"]],[23,0,[\"selectedBusiness\"]],[23,0,[\"selectedKommune\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[[12,\"class\",\"\"]],[[\"@id\",\"@title\"],[\"samtaler\",\"Samtaler\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12 pl-0\"],[8],[0,\"\\n        \"],[5,\"samtaler\",[],[[\"@selectedEndDate\",\"@selectedStartDate\",\"@selectedBusiness\",\"@selectedKommune\",\"@samtaler\"],[[23,0,[\"selectedEndDate\"]],[23,0,[\"selectedStartDate\"]],[23,0,[\"selectedBusiness\"]],[23,0,[\"selectedKommune\"]],[23,0,[\"model\",\"samtaler\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,1,[\"pane\"]],[[12,\"class\",\"col-md-12 pl-0\"]],[[\"@id\",\"@title\"],[\"virksomheder\",\"Virksomheder\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-12 pl-0\"],[8],[0,\"\\n        \"],[5,\"virksomheder\",[],[[\"@selectedEndDate\",\"@selectedStartDate\",\"@selectedBusiness\",\"@selectedKommune\"],[[23,0,[\"selectedEndDate\"]],[23,0,[\"selectedStartDate\"]],[23,0,[\"selectedBusiness\"]],[23,0,[\"selectedKommune\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/demo.hbs"
    }
  });

  _exports.default = _default;
});