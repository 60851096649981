define("job-ag/models/companyrecruitmentcandidate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    personCivilRegistrationIdentifierType: _emberData.default.attr('string'),
    cvCustomerIdentifier: _emberData.default.attr('string'),
    candidateComment: _emberData.default.attr('string'),
    candidateUrl: _emberData.default.attr('string'),
    candidateRecruitmentStatusTypeIdentifier: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    postCodeIdentifier: _emberData.default.attr('string'),
    districtSubdivisionIdentifier: _emberData.default.attr('string'),
    telephoneNumberIdentifier: _emberData.default.attr('string'),
    faxNumberIdentifier: _emberData.default.attr('string'),
    webAddress: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    personNameStructure: _emberData.default.belongsTo('personnamestructuretype'),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});