define("job-ag/templates/components/loader-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mmcyCNZT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"z-index:100;position:absolute;top:45%;left:37%;font-size:40px;width:300px;text-align:center\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"fa-icon\",[\"spinner\"],[[\"spin\",\"size\"],[true,\"lg\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/loader-progress.hbs"
    }
  });

  _exports.default = _default;
});