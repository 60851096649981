define("job-ag/models/setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    standerId: _emberData.default.attr('string'),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});