define("job-ag/controllers/jobordre/borger/new", ["exports", "job-ag/validations/kandidat"], function (_exports, _kandidat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    KandidatValidations: _kandidat.default,
    actions: {
      save(changeset) {
        let _this = this;
        /*
        	let link = changeset.get('link');
        	link = link.toLowerCase();
        
        	if (!link.startsWith('http')) {
        		if (!link.startsWith(':')) {
        			while (!link.startsWith('//')) {
        			link = `/${link}`;
        			}
        			link = `:${link}`;
        		}
        		link = `http${link}`;
        	}
        	changeset.set('link', link);
        
        	changeset.set('logo', '');
        	changeset.set('database', '');
        	*/


        changeset.save().then(function () {
          history.back(); //	_this.transitionToRoute('demos');
        });
      },

      cancel() {
        Ember.get(this, 'model.Candidate').destroyRecord(); //	history.back();  

        return false;
      }

    }
  });

  _exports.default = _default;
});