define("job-ag/components/virksomheder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    utility: Ember.inject.service("utility"),
    selectedStartDate: null,
    selectedEndDate: null,
    selectedKommune: null,

    init() {
      this._super(...arguments);

      const {
        defaultStartDate,
        defaultEndDate
      } = this.utility.getDefaultDateRangeForDatePicker({
        splitPeriode: true
      });
      Ember.set(this, 'selectedStartDate', defaultStartDate);
      Ember.set(this, 'selectedEndDate', defaultEndDate);
    },

    actions: {
      chosenKommune(kommune) {
        Ember.set(this, 'selectedKommune', kommune);
      },

      chossenDateRange(start, slut) {
        Ember.set(this, 'selectedStartDate', start);
        Ember.set(this, 'selectedEndDate', slut);
      }

    }
  });

  _exports.default = _default;
});