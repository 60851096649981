define("job-ag/templates/components/filter-arbejdstid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NQ9lpxhH",
    "block": "{\"symbols\":[\"arbejdstid\"],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\"],[\"toggle-on\",\"Arbejdstider\"]],{\"statements\":[[0,\"\\n\"],[4,\"data-power-select\",null,[[\"modelName\",\"queryKey\",\"searchEnabled\",\"loadingMessage\",\"searchMessage\",\"noMatchesMessage\",\"onChange\",\"selected\",\"placeholder\",\"triggerClass\",\"dropdownClass\",\"allowClear\"],[\"weeklyworktimetype\",\"searchWord\",true,\"Indlæser...\",\"Indtast navn i søgefeltet\",\"Ingen arbejdstider opfylder søgekritererne.\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"valgtArbejdstid\"]]],null]],null],[24,[\"valgtArbejdstid\"]],\"Alle\\tarbejdstider\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-arbejdstid.hbs"
    }
  });

  _exports.default = _default;
});