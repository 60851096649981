define("job-ag/templates/components/manualer-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lYpCMZLv",
    "block": "{\"symbols\":[\"manual\"],\"statements\":[[7,\"strong\",true],[8],[0,\"\\n  Manualer\\n\"],[9],[0,\"\\n\"],[7,\"ol\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"style\",\"display: inline-block;margin-right: 100px;\"],[8],[0,\"\\n    \"],[7,\"ol\",true],[10,\"class\",\"list-unstyled\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"manualer\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[11,\"href\",[23,1,[\"file\"]]],[8],[0,\"\\n            \"],[1,[23,1,[\"titel\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/manualer-list.hbs"
    }
  });

  _exports.default = _default;
});