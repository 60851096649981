define("job-ag/models/contactinformationfield", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    personGivenNameField: "Martin",
    personMiddleNameField: "",
    personSurnameNameField: "Wismann",
    telephoneNumberIdentifierField: "",
    emailAddressIdentifierField: "martin@larsprintz.dk" //	contactInformationField	: DS.belongsTo('joborder')

  });

  _exports.default = _default;
});