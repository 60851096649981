define("job-ag/components/side-menu", ["exports", "jquery", "job-ag/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),
    tagName: 'div',
    visOpret: _environment.default.APP.MENU_Opret,
    visBorgere: Ember.computed('ENV.APP.MENU_Borgere', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Borgere && Ember.get(this, 'erJobcenterBruger');
    }),
    visRapporter: Ember.computed('ENV.APP.MENU_Rapporter', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Rapporter && Ember.get(this, 'erJobcenterBruger');
    }),
    visStartsider: Ember.computed('ENV.APP.MENU_Startsider', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Startsider && Ember.get(this, 'erJobcenterBruger');
    }),
    visTrimme: Ember.computed('ENV.APP.MENU_Trimme', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Trimme && Ember.get(this, 'erJobcenterBruger');
    }),
    visLog: Ember.computed('ENV.APP.MENU_Log', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Log && Ember.get(this, 'erJobcenterBruger');
    }),
    visExchangekonti: Ember.computed('ENV.APP.MENU_Exchangekonti', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Exchangekonti && Ember.get(this, 'erJobcenterBruger');
    }),
    visOrganisationer: Ember.computed('ENV.APP.MENU_Organisationer', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Organisationer && Ember.get(this, 'erJobcenterBruger');
    }),
    visManualer: Ember.computed('ENV.APP.MENU_Manualer', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Manualer && Ember.get(this, 'erJobcenterBruger');
    }),
    visLink: Ember.computed('ENV.APP.MENU_Link', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Link && Ember.get(this, 'erJobcenterBruger');
    }),
    visBrugere: Ember.computed('ENV.APP.MENU_Brugere', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Brugere && Ember.get(this, 'erJobcenterBruger');
    }),
    visBeskeder: Ember.computed('ENV.APP.MENU_Beskeder', 'erJobcenterBruger', function () {
      return _environment.default.APP.MENU_Beskeder && Ember.get(this, 'erJobcenterBruger');
    }),
    visSikkerhedslog: Ember.computed('ENV.APP.visSikkerhedslog', 'erJobcenterBruger', function () {
      return _environment.default.APP.visSikkerhedslog && Ember.get(this, 'erJobcenterBruger');
    }),
    visOvervaagning: _environment.default.APP.Overvaagning,

    didReceiveAttrs() {
      this._super(...arguments);

      let currentUser = Ember.get(this, 'currentUser');
    },

    didInsertElement: function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        // CURRENT_URL = window.location.href.split('?')[0],
        var $BODY = (0, _jquery.default)('body'),
            $MENU_TOGGLE = (0, _jquery.default)('#menu_toggle'),
            $SIDEBAR_MENU = (0, _jquery.default)('#sidebar-menu'),
            $SIDEBAR_FOOTER = (0, _jquery.default)('.sidebar-footer'),
            $LEFT_COL = (0, _jquery.default)('.left_col'),
            $RIGHT_COL = (0, _jquery.default)('.right_col'),
            $NAV_MENU = (0, _jquery.default)('.nav_menu'),
            $FOOTER = (0, _jquery.default)('footer');

        var setContentHeight = function () {
          // reset height
          $RIGHT_COL.css('min-height', (0, _jquery.default)(window).height());
          var bodyHeight = $BODY.outerHeight(),
              footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
              leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
              contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight; // normalize content

          contentHeight -= $NAV_MENU.height() + footerHeight;
          $RIGHT_COL.css('min-height', contentHeight);
        };

        $SIDEBAR_MENU.find('a').on('click', function () {
          var $li = (0, _jquery.default)(this).parent();

          if ($li.is('.active')) {
            $li.removeClass('active active-sm');
            (0, _jquery.default)('ul:first', $li).slideUp(function () {
              setContentHeight();
            });
          } else {
            // prevent closing menu if we are on child menu
            if (!$li.parent().is('.child_menu')) {
              $SIDEBAR_MENU.find('li').removeClass('active active-sm');
              $SIDEBAR_MENU.find('li ul').slideUp();
            }

            $li.addClass('active');
            (0, _jquery.default)('ul:first', $li).slideDown(function () {
              setContentHeight();
            });
          }
        }); // toggle small or large menu

        $MENU_TOGGLE.on('click', function () {
          if ($BODY.hasClass('nav-md')) {
            $SIDEBAR_MENU.find('li.active ul').hide();
            $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
          } else {
            $SIDEBAR_MENU.find('li.active-sm ul').show();
            $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
          }

          $BODY.toggleClass('nav-md nav-sm');
          setContentHeight();
        }); // check active menu
        //$SIDEBAR_MENU.find('a[href="' + CURRENT_URL + '"]').parent('li').addClass('current-page');

        /*  $SIDEBAR_MENU.find('a').filter(function () {
        	return this.href == CURRENT_URL;
        }).parent('li').addClass('current-page').parents('ul').slideDown(function() {
        	setContentHeight();
        }).parent().addClass('active');*/

        /*   // recompute content when resizing
         $(window).smartresize(function(){  
        	 setContentHeight();
         });
        */

        setContentHeight(); // fixed sidebar

        /*if ($.fn.mCustomScrollbar) {
        		$('.menu_fixed').mCustomScrollbar({
        			autoHideScrollbar: true,
        			theme: 'minimal',
        			mouseWheel:{ preventDefault: true }
        		});
        	}*/
      });
    }
  });

  _exports.default = _default;
});