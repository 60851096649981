define("job-ag/models/aktiviteter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    favorit: _emberData.default.attr('boolean'),
    companyRecruitmentIdentifierField: _emberData.default.attr('string'),
    companyRecruitmentIdentifierFieldSpecified: _emberData.default.attr('boolean'),
    companyRecruitmentTitleField: _emberData.default.attr('string'),
    occupationIdField: _emberData.default.attr('string'),
    companyRecruitmentJobDescriptionField: _emberData.default.attr('string'),
    companyRecruitmentCommentField: _emberData.default.attr('string'),
    companyRecruitmentTypeIdentifierField: _emberData.default.attr('number'),
    companyRecruitmentStatusTypeIdentifierField: _emberData.default.attr('number'),
    companyRecruitmentStartDateField: _emberData.default.attr('date'),
    companyRecruitmentStartDateFieldSpecified: _emberData.default.attr('boolean'),
    companyRecruitmentPeriodField: _emberData.default.attr('number'),
    companyRecruitmentPeriodFieldSpecified: _emberData.default.attr('boolean'),
    companyRecruitmentValidToDateField: _emberData.default.attr('date'),
    companyRecruitmentValidToDateFieldSpecified: _emberData.default.attr('boolean'),
    companyRecruitmentRespondTypeIdentifierField: _emberData.default.attr('number'),
    maximumNumberOfResponsesField: _emberData.default.attr('number'),
    maximumNumberOfResponsesFieldSpecified: _emberData.default.attr('boolean'),
    weeklyWorkTimeTypeIdentifierField: _emberData.default.attr('number'),
    hoursPerWeekField: _emberData.default.attr('number'),
    hoursPerWeekFieldSpecified: _emberData.default.attr('boolean'),
    companyRecruitmentProviderField: _emberData.default.belongsTo('companyrecruitmentprovidertype'),
    companyRecruitmentLocationNameField: _emberData.default.attr('string'),
    lastModifiedDateTime: _emberData.default.attr('date'),
    companyRecruitmentLocationAddressField: _emberData.default.belongsTo('address-postal-type'),
    contactInformationField: _emberData.default.belongsTo('contactinformationfield'),
    responsibleJobcenterCodeField: _emberData.default.attr('string'),
    isCreatedByCompanyField: _emberData.default.attr('boolean'),
    jobAdIdField: _emberData.default.attr('number'),
    jobAdIdFieldSpecified: _emberData.default.attr('boolean'),
    serialNumberField: _emberData.default.attr('number'),
    createdDateTimeField: _emberData.default.attr('date'),
    wantsEmailNotificationField: _emberData.default.attr('boolean'),
    RecruitmentCaseWorkerAllowed: _emberData.default.attr('boolean'),
    recruitmentCaseWorkerField: _emberData.default.belongsTo('responsibleauthoritytype'),
    internalAuthorityCommentField: _emberData.default.attr('string'),
    numberOfCandidates: _emberData.default.attr('number'),
    primaryRecruitmentAuthoritiesField: _emberData.default.hasMany('primaryrecruitmentauthoritiestype'),
    // bliver ikke overfoert til server
    combinedAddress: _emberData.default.attr('string')
  });

  _exports.default = _default;
});