define("job-ag/components/filter-kommune", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    utility: Ember.inject.service(),
    searchStartLength: 0,
    selectedKommune: null,
    actions: {
      onChosenKommune(kommune) {
        Ember.set(this, "selectedKommune", kommune);
        this.onChange(kommune);
      },

      checkLength(text, select) {
        const searchStartLength = this.get("searchStartLength");
        return this.utility.checkSearchStartLength(text, select, searchStartLength);
      }

    }
  });

  _exports.default = _default;
});