define("job-ag/templates/components/filter-kommune", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lh74PIMF",
    "block": "{\"symbols\":[\"kommune\"],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\",\"@contentClass\"],[\"globe\",\"Kommuner\",[23,0,[\"contentClass\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"data-power-select\",[],[[\"@modelName\",\"@searchEnabled\",\"@queryKey\",\"@loadingMessage\",\"@searchMessage\",\"@noMatchesMessage\",\"@onChange\",\"@onInput\",\"@selected\",\"@placeholder\",\"@triggerClass\",\"@dropdownClass\",\"@allowClear\"],[\"kommuner\",true,\"searchWord\",\"Indlæser...\",\"Indtast kommunens navn i søgefeltet\",\"Ingen kommune opfylder søgekriteriet\",[28,\"action\",[[23,0,[]],\"onChosenKommune\"],null],[28,\"action\",[[23,0,[]],\"checkLength\"],null],[22,\"selectedKommune\"],\"Alle kommuner\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"option-container\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-kommune.hbs"
    }
  });

  _exports.default = _default;
});