define("job-ag/controllers/users/new", ["exports", "job-ag/config/environment", "job-ag/validations/account"], function (_exports, _environment, _account) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    AccountValidations: _account.default,
    ajax: Ember.inject.service(),
    actions: {
      save(changeset) {
        const email = changeset.get('email');
        const userName = changeset.get('userName');
        const password = changeset.get('password');
        const confirmPassword = changeset.get('confirmPassword');

        let _this = this;

        let response = this.ajax.request(_environment.default.APP.namespace + '/api/Account/register', {
          method: 'POST',
          data: {
            Email: email,
            Password: password,
            ConfirmPassword: confirmPassword
          }
        });
        /*
         var registerModel = new
        {
        		Email = email,
        		Password = password,
        		ConfirmPassword = password
        };
        using (var client = new HttpClient())
        {
        		var response =
        				client.PostAsJsonAsync(
        				"http://92e03e0e4f02.internetfirmaet.dk/api/Account/Register",
        				registerModel).Result;
        		return response.StatusCode.ToString();
        }
        */

        /*
        
        					 changeset.save().then(function(account) {
        
        						 //    console.log('Bruger/new - save - done');
        
        								});    */
      },

      cancel() {
        // console.log('Bruger/new - cancel');
        this.model.destroyRecord();
        this.transitionToRoute('users');
        return false;
      }

    }
  });

  _exports.default = _default;
});