define("job-ag/models/kundeinformationer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    smtp: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    masterPage: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    prefix: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    feltnavn: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    seperator: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    sMSGateway: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    sMSBrugernavn: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    sMSPassword: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    cPRPos: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    navnPos: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    meetingTypePos: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    skabelon: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    licenser: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    aktiv: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    sql: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    IsFlersporet: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    MasseIndkaldelse: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    VisNummer: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});