define("job-ag/adapters/application", ["exports", "ember-simple-auth/mixins/data-adapter-mixin", "job-ag/config/environment", "ember-data"], function (_exports, _dataAdapterMixin, _environment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let headerList = {
    Accept: 'application/json'
  };

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated');
      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    },

    host: _environment.default.APP.host,
    namespace: _environment.default.APP.namespace,
    headers: Ember.computed('session.data.authenticated.token', function () {
      if (this.session.isAuthenticated) {
        headerList['Authorization'] = `Bearer ${this.session.data.authenticated.token}`;
      }

      return headerList;
    })
  });

  _exports.default = _default;
});