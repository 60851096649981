define("job-ag/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "opBn/sHC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[10,\"role\",\"alert\"],[8],[0,\"\\n\"],[7,\"h2\",true],[8],[0,\"Der er sket en fejl.\"],[9],[0,\"\\n\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/error.hbs"
    }
  });

  _exports.default = _default;
});