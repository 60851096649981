define("job-ag/models/log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    overskrift: _emberData.default.attr('string'),
    kilde: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    besked: _emberData.default.attr('string'),
    tidspunkt: _emberData.default.attr('string'),
    input: _emberData.default.attr('string'),
    statusType: _emberData.default.attr('string'),
    standerid: _emberData.default.attr('string'),
    templatevalues: _emberData.default.attr('string'),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});