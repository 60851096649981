define("job-ag/models/responsiblecaseworkerstructuretype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    caseWorkerGivenNameField: _emberData.default.attr('string'),
    caseWorkerMiddleNameField: _emberData.default.attr('string'),
    caseWorkerSurnameField: _emberData.default.attr('string'),
    telephoneNumberIdentifierField: _emberData.default.attr('string'),
    emailAddressIdentifierField: _emberData.default.attr('string'),
    caseWorkerIdentifierField: _emberData.default.attr('string'),
    fullname: Ember.computed('caseWorkerGivenNameField', 'caseWorkerMiddleNameField', 'caseWorkerSurnameField', function () {
      const firstname = Ember.get(this, 'caseWorkerGivenNameField') || '';
      const middlename = Ember.get(this, 'caseWorkerMiddleNameField') || '';
      const lastname = Ember.get(this, 'caseWorkerSurnameField') || '';

      if (!middlename && middlename === "") {
        return `${firstname} ${lastname}`;
      } else {
        return `${firstname} ${middlename} ${lastname}`;
      }
    })
  });

  _exports.default = _default;
});