define("job-ag/components/card-export", ["exports", "moment", "fremmoedesystem/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var request = function (_this, id, name, PDF) {
    let data = Ember.get(_this, 'params');
    let isPDF = PDF;
    const url = _environment.default.APP.host + "/" + _environment.default.APP.namespace + '/dataexports';
    let version = _environment.default.APP.version;

    if (!version) {
      version = "Ikke defineret";
    }

    let {
      access_token
    } = _this.get('session.data.authenticated');

    return new Ember.RSVP.Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);

        if (!isPDF) {
          xhr.responseType = 'arraybuffer';
        }

        if (Ember.isPresent(access_token)) {
          xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
        }

        xhr.onload = function () {
          if (this.status === 200) {
            resolve(true);
            /*	if (isPDF) {
            		let text = this.responseText;
            		if (text) {
            			var rapport = JSON.parse(text);
            			pdfMake.createPdf( rapport ).download();
            		}
            	}
            	else {*/

            var filename = name;
            var type = xhr.getResponseHeader('Content-Type');
            var blob = typeof File === 'function' ? new File([this.response], filename, {
              type: type
            }) : new Blob([this.response], {
              type: type
            });

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
              window.navigator.msSaveBlob(blob, filename);
            } else {
              var URL = window.URL || window.webkitURL;
              var downloadUrl = URL.createObjectURL(blob);

              if (filename) {
                // use HTML5 a[download] attribute to specify filename
                var a = document.createElement("a"); // safari doesn't support this yet

                if (typeof a.download === 'undefined') {
                  window.location = downloadUrl;
                } else {
                  a.href = downloadUrl;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                }
              } else {
                window.location = downloadUrl;
              }

              setTimeout(function () {
                URL.revokeObjectURL(downloadUrl);
              }, 100); // cleanup
            }
          } else {
            reject(new Error('getJSON: `' + url + '` failed with status: [' + this.status + ']'));
          }
        };

        xhr.setRequestHeader('Content-type', 'application/vnd.api+json');
        xhr.send(data);
      } catch (err) {//console.log("error: " + err);
      }
    });
  };

  var _default = Ember.Component.extend({
    isPdf: false,
    valgtTilbudssted: null,
    valgtBorger: null,
    valgtAktivitet: null,
    valgtStartdato: null,
    valgtSlutdato: null,
    ajax: Ember.inject.service(),
    session: Ember.inject.service('session'),
    authorizerName: Ember.computed('ENV.APP.saml', function () {
      var isSaml = Ember.get(_environment.default, 'APP.saml');
      var result = 'authorizer: oauth2';

      if (isSaml) {
        result = 'authorizer: saml';
      }

      return result;
    }),
    params: Ember.computed('isPdf', 'valgtTilbudssted', 'valgtBorger', 'valgtAktivitet', 'valgtStartdato', 'valgtSlutdato', function () {
      const valgtTilbudssted = Ember.get(this, 'valgtTilbudssted');
      const valgtTilbudsstedId = valgtTilbudssted ? valgtTilbudssted.id : -1;
      const valgtBorger = Ember.get(this, 'valgtBorger');
      const valgtBorgerId = valgtBorger ? valgtBorger.id : -1;
      const valgtAktivitet = Ember.get(this, 'valgtAktivitet');
      const valgtAktivitetId = valgtAktivitet ? valgtAktivitet.id : -1;
      const valgtStartdato = Ember.get(this, 'valgtStartdato');
      const valgtSlutdato = Ember.get(this, 'valgtSlutdato');
      let data = '{ "data" : {	"type": "dataexport","attributes": {';
      data += '"id" : 1\n';
      let isPdf = Ember.get(this, 'isPdf');
      data += ',"filtertext": ""\n';
      data += ',"pdf" : ' + isPdf + '\n';
      let rapportID = Ember.get(this, 'rapport.id');

      if (rapportID) {
        data += ',"rapport-id" : ' + rapportID + '\n';
      }

      if (valgtTilbudssted) {
        data += ',"valgt-tilbudssted" : "' + valgtTilbudsstedId + '"\n';
      }

      if (valgtBorger) {
        data += ',"valgt-borger" : ' + valgtBorgerId + '\n';
      }

      if (valgtAktivitet) {
        data += ',"valgt-aktivitet" : ' + valgtAktivitetId + '\n';
      }

      if (_environment.default.APP.version) {
        data += ',"version"  : "' + _environment.default.APP.version + '"\n';
      }

      if (valgtStartdato) {
        data += ',"valgt-startdato" : "' + (0, _moment.default)(valgtStartdato, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss') + '"\n';
      }

      if (valgtSlutdato) {
        data += ',"valgt-slutdato"  : "' + (0, _moment.default)(valgtSlutdato, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss') + '"\n';
      }

      data += ' }}}';
      return data;
    }),
    actions: {
      gemPDF(id) {
        if (id) {
          Ember.set(this, "isPdf", true);
          return request(this, id, "fravaer.pdf", true);
        }
      },

      gemExcel(id) {
        if (id) {
          Ember.set(this, "isPdf", false);
          return request(this, id, "fravaer.xlsx", false);
        }
      }

    }
  });

  _exports.default = _default;
});