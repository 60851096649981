define("job-ag/templates/components/infoboks-stilling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BHKjDRcn",
    "block": "{\"symbols\":[],\"statements\":[[5,\"small-box\",[],[[\"@contentClass\",\"@iconname\",\"@title\"],[[23,0,[\"contentClass\"]],\"industry\",\"Stillingsbeskrivelse\"]],{\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12 col-xs-12 pl-0 pr-0\"],[10,\"style\",\"\"],[8],[0,\"\\n\"],[7,\"strong\",true],[8],[1,[23,0,[\"joborder\",\"companyRecruitmentTitleField\"]],false],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"joborder\",\"companyRecruitmentJobDescriptionField\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"visAlt\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"pre\",true],[10,\"style\",\"white-space:pre-wrap;\"],[8],[1,[23,0,[\"joborder\",\"companyRecruitmentJobDescriptionField\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"pre\",true],[10,\"style\",\"white-space:pre-wrap;height:250px;overflow:hidden;\"],[8],[1,[23,0,[\"joborder\",\"companyRecruitmentJobDescriptionField\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[8],[0,\"Der er ingen stillingsbeskrivelse.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"visAlt\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-sm\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"toogleAlt\"],null]],[8],[0,\"Se mindre\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary btn-sm\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"toogleAlt\"],null]],[8],[0,\"Se mere\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/infoboks-stilling.hbs"
    }
  });

  _exports.default = _default;
});