define("job-ag/templates/startside/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dnNIyJcS",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\"],[\"Opret startside\",\"Luk\",[28,\"action\",[[23,0,[]],\"cancel\"],null],\"true\",\"Opret\"]],{\"statements\":[[4,\"validated-form\",null,[[\"model\",\"on-submit\",\"submit-label\"],[[28,\"changeset\",[[24,[\"model\"]],[24,[\"StartsideValidations\"]]],null],[28,\"action\",[[23,0,[]],\"save\"],null],\"hiddenSave\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/startside/new.hbs' @ L8:C3) \"],null]],[[\"label\",\"name\",\"class\"],[\"Navn\",\"sidenavn\",\"col-md-12\"]]],false],[0,\"\\n\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/startside/new.hbs' @ L9:C3) \"],null]],[[\"label\",\"name\",\"type\",\"class\"],[\"Adresse\",\"filnavn\",\"url\",\"col-md-12\"]]],false],[0,\"\\n\\t\"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/startside/new.hbs' @ L10:C3) \"],null]],[[\"label\",\"name\",\"type\",\"class\"],[\"Rækkefølge\",\"placering\",\"number\",\"col-md-3\"]]],false],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\" \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/startside/new.hbs"
    }
  });

  _exports.default = _default;
});