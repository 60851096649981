define("job-ag/controllers/jobordre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchWord'],
    searchWord: null,
    visningsType: 0,
    Authorities: Ember.computed('model.joborder.primaryRecruitmentAuthoritiesField', function () {
      let Authorities = Ember.get(this, 'model.joborder.primaryRecruitmentAuthoritiesField');
      const typer = Ember.get(this, 'model.OrganisationIdentifiers');
      const authoritylist = Ember.get(this, 'model.JobcenterIdentifiers');
      let identifier = '';
      Authorities.forEach(function (autoritet) {
        identifier = Ember.get(autoritet, 'organisationTypeIdentifierField');

        if (identifier) {
          typer.forEach(function (element) {
            if (Ember.get(element, 'identifier') === identifier.toString()) {
              Ember.set(autoritet, 'organisationTypeName', Ember.get(element, 'name'));
            }
          }, this);
        }

        identifier = Ember.get(autoritet, 'authorityCodeField');

        if (identifier) {
          authoritylist.forEach(function (element) {
            if (Ember.get(element, 'identifier') === identifier.toString()) {
              Ember.set(autoritet, 'authorityName', Ember.get(element, 'name'));
              Ember.set(autoritet, 'address', Ember.get(element, 'address'));
              Ember.set(autoritet, 'postCodeIdentifier', Ember.get(element, 'postCodeIdentifier'));
              Ember.set(autoritet, 'districtSubdivisionIdentifier', Ember.get(element, 'districtSubdivisionIdentifier'));
              Ember.set(autoritet, 'telephoneNumberIdentifier', Ember.get(element, 'telephoneNumberIdentifier'));
              Ember.set(autoritet, 'faxNumberIdentifier', Ember.get(element, 'faxNumberIdentifier'));
              Ember.set(autoritet, 'webAddress', Ember.get(element, 'webAddress'));
              Ember.set(autoritet, 'email', Ember.get(element, 'email'));
            }
          }, this);
        }
      });
      return Authorities;
    }),
    CompanyRecruitmentType: Ember.computed('model.joborder.companyRecruitmentTypeIdentifierField', function () {
      const typer = Ember.get(this, 'model.CompanyRecruitmentTypeIdentifiers');
      const identifier = Ember.get(this, 'model.joborder.companyRecruitmentTypeIdentifierField');
      let recruitmenttype = '';

      if (identifier) {
        typer.forEach(function (element) {
          if (Ember.get(element, 'identifier') === identifier.toString()) {
            recruitmenttype = Ember.get(element, 'name'); //break;
          }
        }, this);
      }

      return recruitmenttype;
    }),
    CompanyRecruitmentStatusType: Ember.computed('model.joborder.companyRecruitmentStatusTypeIdentifierField', function () {
      const typer = Ember.get(this, 'model.CompanyRecruitmentStatusTypeIdentifiers');
      const identifier = Ember.get(this, 'model.joborder.companyRecruitmentStatusTypeIdentifierField');
      let recruitmenttype = '';

      if (identifier) {
        typer.forEach(function (element) {
          if (Ember.get(element, 'identifier') === identifier.toString()) {
            recruitmenttype = Ember.get(element, 'name'); //break;
          }
        }, this);
      }

      return recruitmenttype;
    }),
    CompanyRecruitmentRespondType: Ember.computed('model.joborder.companyRecruitmentRespondTypeIdentifierField', function () {
      const typer = Ember.get(this, 'model.CompanyRecruitmentRespondTypeIdentifiers');
      const identifier = Ember.get(this, 'model.joborder.companyRecruitmentRespondTypeIdentifierField');
      let recruitmenttype = '';

      if (identifier) {
        typer.forEach(function (element) {
          if (Ember.get(element, 'identifier') === identifier.toString()) {
            recruitmenttype = Ember.get(element, 'name'); //break;
          }
        }, this);
      }

      return recruitmenttype;
    }),
    WeeklyWorkTimeType: Ember.computed('model.joborder.weeklyWorkTimeTypeIdentifierField', function () {
      const typer = Ember.get(this, 'model.WeeklyWorkTimeTypeIdentifiers');
      const identifier = Ember.get(this, 'model.joborder.weeklyWorkTimeTypeIdentifierField');
      let recruitmenttype = '';

      if (identifier) {
        typer.forEach(function (element) {
          if (Ember.get(element, 'identifier') === identifier.toString()) {
            recruitmenttype = Ember.get(element, 'name'); //break;
          }
        }, this);
      }

      return recruitmenttype;
    }),
    virksomhedsAdresse: Ember.computed('model.@each', function () {
      return `${Ember.get(this, 'model.joborder.companyRecruitmentLocationAddressField.districtNameField')}, ${Ember.get(this, 'model.joborder.companyRecruitmentLocationAddressField.streetNameField')}  ${Ember.get(this, 'model.joborder.companyRecruitmentLocationAddressField.streetBuildingIdentifierField')}`;
    }),
    borgere: Ember.computed('model', function () {
      return [{
        id: 1,
        PersonCivilRegistrationIdentifier: '1234561234',
        CvCustomerIdentifier: '123456789',
        CandidateComment: 'En kommentar til kandidaten.',
        CandidateUrl: 'http://www.google.com',
        CandidateRecruitmentStatusTypeIdentifier: 'Formidling tilgængelig for virksomhed',
        PersonNameStructurePersonGivenName: 'Jens',
        PersonNameStructurePersonMiddleName: '',
        PersonNameStructurePersonSurnameName: 'Jensen',
        PersonName: 'Jens Jensen'
      }, {
        id: 2,
        PersonCivilRegistrationIdentifier: '1234561234',
        CvCustomerIdentifier: '123456789',
        CandidateComment: 'En kommentar til kandidaten.',
        CandidateUrl: 'http://www.google.com',
        CandidateRecruitmentStatusTypeIdentifier: 'Formidling tilgængelig for virksomhed',
        PersonNameStructurePersonGivenName: 'Peter',
        PersonNameStructurePersonMiddleName: '',
        PersonNameStructurePersonSurnameName: 'Petersen',
        PersonName: 'Peter Petersen'
      }];
    }),
    disableIfTooMany: Ember.computed('model', function () {
      let MaximumNumberOfResponses = Ember.get(this, 'model.joborder.maximumNumberOfResponsesField');
      let NumberOfCandidates = Ember.get(this, 'model.joborder.numberOfCandidates');
      return NumberOfCandidates >= MaximumNumberOfResponses ? " disabled " : "";
    }),
    actions: {
      setSearchValue(searchWord) {
        Ember.set(this, 'searchWord', searchWord);
      }

    }
  });

  _exports.default = _default;
});