define("job-ag/validations/joborder", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    companyRecruitmentJobDescriptionField: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 1,
      max: 2000
    })],
    companyRecruitmentCommentField: [(0, _validators.validateLength)({
      allowBlank: true,
      max: 2000
    })],
    companyRecruitmentTitleField: [(0, _validators.validateLength)({
      allowBlank: true,
      max: 100
    })],
    occupationIdField: [(0, _validators.validateLength)({
      allowBlank: true,
      max: 100
    })],
    companyRecruitmentTypeIdentifierField: [(0, _validators.validatePresence)(true)],
    wantsEmailNotificationField: [(0, _validators.validatePresence)(true)],
    RecruitmentCaseWorkerAllowed: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});