define("job-ag/models/sikkerhedslog", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tidspunkt: _emberData.default.attr('date'),
    brugerId: _emberData.default.attr('number'),
    brugerNavn: _emberData.default.attr('string'),
    brugerFuldtNavn: _emberData.default.attr('string'),
    tilgangstype: _emberData.default.attr('string'),
    resultat: _emberData.default.attr('string'),
    databeskrivelse: _emberData.default.attr('string'),
    filterPeriodeStart: _emberData.default.attr('date'),
    filterPeriodeSlut: _emberData.default.attr('date'),
    filterTekst: _emberData.default.attr('string'),
    udvidetFilter1: _emberData.default.attr('string'),
    udvidetFilter2: _emberData.default.attr('string'),
    udvidetFilter3: _emberData.default.attr('string'),
    udvidetFilter4: _emberData.default.attr('string')
  });

  _exports.default = _default;
});