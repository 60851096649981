define("job-ag/components/manualer-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    manualer: null,

    init() {
      this._super(...arguments);

      this.manualer = [];

      this._getManualer();
    },

    _getManualer() {
      this.get('store').findAll('manual').then(manualer => {
        // because this is async you need to guard against the component 
        // being destroyed before the api call has finished because 
        // because `this.set` will throw an error.
        if (this.isDestroyed) {
          return;
        }

        if (manualer) {
          this.set('manualer', manualer);
        }
      });
    }

  });

  _exports.default = _default;
});