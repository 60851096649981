define("job-ag/controllers/statistik", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const printThisOptions = {
    canvas: true
  };
  var chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
  };
  var chartoptions = {
    title: {
      display: false,
      text: 'blabla'
    },
    legend: false
  };
  var statusOptions = {
    responsive: true,
    legend: {
      fullwidth: false,
      position: 'left'
    },
    title: {
      fontSize: 18,
      fontFamily: 'sans-serif',
      display: true,
      text: 'Status på aktive jobordrer'
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
  const chartData = [{
    data: [300, 50, 100],
    backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
  }];

  var _default = Ember.Controller.extend({
    searchword: '',
    valgtRolle: null,
    valgtVirksomhed: null,
    valgtStartdato: null,
    valgtSlutdato: null,
    valgtKommune: null,
    valgtBranche: null,
    valgtStatus: null,
    valgtArbejdstid: null,
    printThisOptions: printThisOptions,
    chartData: chartData,
    chartoptions: chartoptions,
    udenkandidaterData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Jobordre uden kandidater",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [20, 46, 46, 41, 38, 48, 42],
          spanGaps: false
        }]
      };
    }),
    statusOptions: statusOptions,
    statusData: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [12, 8, 5, 25, 21],
          backgroundColor: [chartColors.red, chartColors.orange, chartColors.yellow, chartColors.green, chartColors.blue],
          label: 'Dataset 1'
        }],
        labels: ['Afventer godkendelse', 'Tilgængelig for virksomhed', 'Kandidat fjernet', 'Ansat af virksomheden', 'Ikke ansat af virksomheden']
      };
    }),
    antalAnsatData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Ansat af virksomheden",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [8, 2, 12, 17, 10, 14, 12],
          spanGaps: false
        }, {
          label: "Ikke ansat af virksomheden",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255, 99, 132, 0.4)",
          borderColor: "rgba(255,99,132,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(255,99,132,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255,99,132,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [16, 8, 5, 12, 21, 14, 9],
          spanGaps: false
        }, {
          label: "Kandidat fjernet - ikke mere relevant",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255, 206, 86, 0.4)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(255, 206, 86, 1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255, 206, 86, 1)",
          pointHoverBorderColor: "rgba(255, 206, 86, 1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [20, 12, 8, 10, 26, 12, 13],
          spanGaps: false
        }]
      };
    }),
    antalJobordreData: Ember.computed('model', function () {
      return {
        labels: ["November", "December", "Januar", "Februar", "Marts", "April", "Maj"],
        datasets: [{
          label: "Antal tilgængelige jobordrer",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [220, 232, 240, 231, 228, 228, 232],
          spanGaps: false
        }]
      };
    }),
    actions: {
      submit() {//	console.log(`submit filters`);
      },

      setSearchValue(searchWord) {
        Ember.set(this, 'searchWord', searchWord);
      },

      setVirksomhed(valgtVirksomhed) {
        Ember.set(this, 'valgtVirksomhed', valgtVirksomhed);
      },

      setRoller(valgtRolle) {
        Ember.set(this, 'valgtRolle', valgtRolle);
      },

      setKommune(kommune) {
        Ember.set(this, 'valgtKommune', kommune);
      },

      setBranche(branche) {
        Ember.set(this, 'valgtBranche', branche);
      },

      setStatus(status) {
        Ember.set(this, 'valgtStatus', status);
      },

      setDateRange(start, slut) {
        Ember.set(this, 'valgtStartdato', start);
        Ember.set(this, 'valgtSlutdato', slut);
      },

      setArbejdstid(arbejdstid) {
        Ember.set(this, 'valgtArbejdstid', arbejdstid);
      }

    }
  });

  _exports.default = _default;
});