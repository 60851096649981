define("job-ag/routes/statistik", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        brancher: [],
        kommuner: [],
        joborder: [],
        CompanyRecruitmentStatusTypes: [],
        WeeklyWorkTimeTypes: []
        /*		brancher: this.store.findAll('discoams08'),
        		kommuner: this.store.findAll('kommuner'),
        		joborder: this.store.findAll('joborder'),
        		CompanyRecruitmentStatusTypes: this.store.findAll('companyrecruitmentstatustype'),
        		Weeklyworktimetypes: this.store.findAll('weeklyworktimetype')*/

      });
    }

  });

  _exports.default = _default;
});