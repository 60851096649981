define("job-ag/mixins/loading-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    loadingSlider: Ember.inject.service(),
    actions: {
      loading() {
        let loadingSliderService = this.get('loadingSlider');
        loadingSliderService.startLoading();

        if (Ember.isPresent(this._router)) {
          this._router.one('didTransition', function () {
            loadingSliderService.endLoading();
          });
        }

        if (this.get('bubbleLoadingSlider')) {
          return true;
        }
      },

      finished() {
        this.get('loadingSlider').endLoading();
      }

    }
  });

  _exports.default = _default;
});