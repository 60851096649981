define("job-ag/components/validated-form/component", ["exports", "ember-validated-form/components/validated-form/component", "job-ag/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    config: _environment.default['ember-validated-form']
  });

  _exports.default = _default;
});