define("job-ag/components/manual-form", ["exports", "fremmoedesystem/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileUploadLink: Ember.computed(function () {
      return _environment.default.APP.namespaceRoot + '/v1/uploadImage.ashx';
    }),
    addedFileEvent: Ember.computed(function () {
      var that = this;
      return function (file) {
        let filename = '';

        if (file && file.xhr && file.xhr.responseText) {
          filename = file.xhr.responseText;
        }

        Ember.set(that, 'manual.file', filename);
      };
    })
  });

  _exports.default = _default;
});