define("job-ag/templates/components/card-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJTpYik+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"headerclasses\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"x_panel tile\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"x_title\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"\\n        \"],[7,\"i\",true],[11,\"class\",[22,\"iconclasses\"]],[8],[9],[0,\"\\n        \"],[1,[22,\"title\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"nav navbar-right panel_toolbox\"],[8],[0,\"\\n        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",false],[12,\"class\",\"collapse-link\"],[3,\"action\",[[23,0,[]],\"toggleMax\"]],[8],[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"chevron-up\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[10,\"disabled\",\"disabled\"],[10,\"class\",\"close-link\"],[8],[0,\"\\n            \"],[1,[28,\"fa-icon\",[\"close\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"x_content \",[22,\"contentclasses\"],\" \",[22,\"height\"]]]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/card-element.hbs"
    }
  });

  _exports.default = _default;
});