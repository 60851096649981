define("job-ag/templates/components/filter-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9XbrCsZ",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[5,\"small-box\",[],[[\"@iconname\",\"@title\"],[\"toggle-on\",\"Status\"]],{\"statements\":[[0,\"\\n\"],[4,\"data-power-select\",null,[[\"modelName\",\"searchEnabled\",\"queryKey\",\"loadingMessage\",\"searchMessage\",\"noMatchesMessage\",\"onChange\",\"selected\",\"placeholder\",\"triggerClass\",\"dropdownClass\",\"allowClear\"],[\"companyrecruitmentstatustype\",true,\"searchWord\",\"Indlæser...\",\"Indtast navn i søgefeltet\",\"Ingen status opfylder søgekritererne.\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"valgtStatus\"]]],null]],null],[24,[\"valgtStatus\"]],\"Alle\\tstatus\",\"bootstrap-theme-trigger\",\"slide-fade bootstrap-theme-dropdown\",true]],{\"statements\":[[0,\"\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/filter-status.hbs"
    }
  });

  _exports.default = _default;
});