define("job-ag/templates/components/manual-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jqymWzYj",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\",\"class\"],[[24,[\"title\"]],\"Luk\",[24,[\"cancel\"]],\"true\",[24,[\"title\"]],\"alwaysScrollY\"]],{\"statements\":[[4,\"validated-form\",null,[[\"classNames\",\"model\",\"on-submit\",\"submit-label\"],[\"row\",[28,\"changeset\",[[24,[\"manual\"]],[24,[\"validation\"]]],null],[24,[\"save\"]],\"hiddenSave\"]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/components/manual-form.hbs' @ L15:C6) \"],null]],[[\"label\",\"required\",\"placeholder\",\"name\",\"class\"],[\"Titel\",true,\"Titel\",\"titel\",\"col-md-12\"]]],false],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n      \"],[7,\"b\",true],[8],[0,\"\\n        Dokumentet (PDF format)\\n      \"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"manual\",\"file\"]]],null,{\"statements\":[[0,\"        \"],[1,[24,[\"manual\",\"file\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[0,\"\\n          Intet dokument valgt/uploaded endnu.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"p\",true],[8],[9],[0,\"\\n      \"],[1,[28,\"drop-zone\",null,[[\"url\",\"complete\",\"addRemoveLinks\",\"acceptedFiles\",\"dictDefaultMessage\"],[[24,[\"fileUploadLink\"]],[24,[\"addedFileEvent\"]],true,\"application/pdf\",\"Træk dokumentet hertil, for at uploade\\n\\t\\tdokumentet og vælge det.\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[1,[22,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/manual-form.hbs"
    }
  });

  _exports.default = _default;
});