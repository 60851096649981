define("job-ag/helpers/regexp-replace", ["exports", "ember-string-helpers/helpers/regexp-replace"], function (_exports, _regexpReplace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _regexpReplace.default;
    }
  });
  Object.defineProperty(_exports, "regexpReplace", {
    enumerable: true,
    get: function () {
      return _regexpReplace.regexpReplace;
    }
  });
});