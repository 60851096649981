define("job-ag/templates/components/card-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OTJcDqsG",
    "block": "{\"symbols\":[\"message\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-12 col-sm-12 form-group\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredAndSorted\"]]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"style\",\"width:100%;text-align: center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"\\n          Der er ingen beskeder som opfylder søgeparametrene.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h1\",true],[8],[0,\"\\n          Der er ingen beskeder endnu.\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/card-list.hbs"
    }
  });

  _exports.default = _default;
});