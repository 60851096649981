define("job-ag/models/address-postal-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    mailDeliverySublocationIdentifierField: _emberData.default.attr('string'),
    streetNameField: _emberData.default.attr('string'),
    streetNameForAddressingNameField: _emberData.default.attr('string'),
    streetBuildingIdentifierField: _emberData.default.attr('string'),
    floorIdentifierField: _emberData.default.attr('string'),
    suiteIdentifierField: _emberData.default.attr('string'),
    districtSubdivisionIdentifierField: _emberData.default.attr('string'),
    postOfficeBoxIdentifierField: _emberData.default.attr('string'),
    postCodeIdentifierField: _emberData.default.attr('string'),
    districtNameField: _emberData.default.attr('string'),
    countryIdentificationCodeField: _emberData.default.attr('string'),
    address: Ember.computed('streetNameField', 'streetBuildingIdentifierField', 'postCodeIdentifierField', 'districtNameField', function () {
      const streetname = Ember.get(this, 'streetNameField') || '';
      const buildingnumber = Ember.get(this, 'streetBuildingIdentifierField');
      const postalCode = Ember.get(this, 'postCodeIdentifierField') || '';
      const cityname = Ember.get(this, 'districtNameField');
      return `${streetname} ${buildingnumber}, ${postalCode} ${cityname}`;
    })
  });

  _exports.default = _default;
});