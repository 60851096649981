define("job-ag/routes/joborder/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "job-ag/mixins/loading-slider"], function (_exports, _authenticatedRouteMixin, _loadingSlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_loadingSlider.default, _authenticatedRouteMixin.default, {
    setupController(controller, item) {
      this._super(controller, item);
      /*const kommunenr = item.settings.filterBy('token', 'kommunenr');
      	if (kommunenr && kommunenr.length > 0)
      {
      	const defaultKommune = item.kommuner.filterBy('identifier', get(kommunenr[0],'value'));		
      		if (defaultKommune && defaultKommune.length > 0)
      	{
      		this.controllerFor('joborder.new').set('kommune', defaultKommune[0]);
      	}
      }*/

    },

    model() {
      return Ember.RSVP.hash({
        newJoborder: this.store.createRecord('joborder', {
          companyRecruitmentStartDateField: new Date(),
          companyRecruitmentValidToDateField: new Date(),
          wantsEmailNotificationField: true,
          companyRecruitmentTypeIdentifierField: 1,
          companyRecruitmentTitleField: '',
          companyRecruitmentJobDescriptionField: '',
          companyRecruitmentCommentField: '',
          weeklyWorkTimeTypeIdentifierField: 3,
          occupationIdField: '',
          hoursPerWeekField: '',
          internalAuthorityCommentField: '',
          RecruitmentCaseWorkerAllowed: false
        }),
        jobcentre: [],
        //this.store.findAll('jobcenter'),
        brancher: [],
        // this.store.findAll('discoams08'),
        kommuner: [],
        //this.store.findAll('kommuner'),
        settings: [],
        //this.store.findAll('setting'),
        CompanyRecruitmentTypeIdentifier: [],
        //this.store.findAll('companyrecruitmenttypeidentifier'),
        CompanyRecruitmentStatusTypes: [],
        //this.store.findAll('companyrecruitmentstatustype'),
        CompanyRecruitmentRespondTypeIdentifiers: [],
        //this.store.findAll('companyrecruitmentrespondtype'),
        WeeklyWorkTimeTypes: [] //this.store.findAll('weeklyworktimetype')

      });
    }

  });

  _exports.default = _default;
});