define("job-ag/controllers/startside/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      delete: function () {
        var _this = this;

        this.model.destroyRecord().then(function () {
          _this.transitionToRoute('startsider');
        });
      },
      cancel: function () {
        this.transitionToRoute('startsider');
        return false;
      }
    }
  });

  _exports.default = _default;
});