define("job-ag/components/user-menu", ["exports", "job-ag/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: '',
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    VisBrugerMenu: _environment.default.APP.VisBrugerMenu,
    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});