define("job-ag/templates/components/listitem-manual", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ryMNtL9G",
    "block": "{\"symbols\":[\"box\"],\"statements\":[[4,\"listitem-box\",null,[[\"footer\",\"itemtype\"],[[24,[\"buttons\"]],\"Manual\"]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"body\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"manual\",\"isPending\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"style\",\"width:100%;height:50px; margin-top:30px;text-align:center\"],[8],[0,\"\\n          \"],[1,[28,\"fa-icon\",[\"spinner\"],[[\"spin\",\"size\"],[true,\"md\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"h2\",true],[10,\"data-test-title\",\"\"],[10,\"class\",\"breakWord lead\"],[8],[0,\"\\n          \"],[1,[24,[\"manual\",\"titel\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"footer\"],null]],null,{\"statements\":[[4,\"link-to\",null,[[\"tagName\",\"classNames\",\"title\",\"data-test-edit\",\"route\",\"model\"],[\"button\",\"btn btn-primary btn-md\",\"Rediger manual\",true,\"manual.edit\",[24,[\"manual\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"pencil\"],null],false],[0,\" Rediger\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"tagName\",\"classNames\",\"title\",\"data-test-delete\",\"route\",\"model\"],[\"button\",\"btn btn-primary btn-md btn-danger\",\"Slet manual\",true,\"manual.delete\",[24,[\"manual\",\"id\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"ban\"],null],false],[0,\" Slet\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/listitem-manual.hbs"
    }
  });

  _exports.default = _default;
});