define("job-ag/components/infoboks-jobcentre", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    boldholderOrgType: Ember.computed('joborder.recruitmentCaseWorkerField.authorityStructureField.organisationTypeIdentifierField', function () {
      let identifier = Ember.get(this, 'joborder.recruitmentCaseWorkerField.authorityStructureField.organisationTypeIdentifierField');
      let organisationTypeName = identifier == 8 ? "Jobcenter" : "";
      /*
      const typer = get(this, 'model.OrganisationIdentifiers');
      let identifier = get(this, 'model.joborder.recruitmentCaseWorkerField.authorityStructureField.organisationTypeIdentifierField');
      	let organisationTypeName = '';
      	if (identifier)
      {
      	typer.forEach(function(element) {
      			if (get(element, 'identifier') === identifier.toString())
      		{
      			organisationTypeName = get(element, 'name');			
      		}
      		
      	}, this);
      }*/

      return organisationTypeName;
    })
  });

  _exports.default = _default;
});