define("job-ag/templates/components/top-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXgJFbh6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"nav_menu hidden-print\"],[8],[0,\"\\n  \"],[7,\"nav\",true],[10,\"class\",\"d-flex\"],[10,\"style\",\"justify-content:space-between\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"nav toggle col-md-1\"],[10,\"style\",\"padding-top:0px;line-height:26px;flex:0\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"id\",\"menu_toggle\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"bars\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-2\"],[10,\"style\",\"line-height:26px; padding-top:3px\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"id\",\"sidetitel\"],[8],[0,\"\\n        \"],[1,[22,\"appTitel\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-7 d-flex justify-content-end\"],[10,\"style\",\"line-height:26px;padding-top:2px;\"],[8],[0,\"\\n      \"],[1,[28,\"version-info\",null,[[\"buildInfo\"],[[24,[\"buildInfo\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"usermenu\"],[10,\"class\",\"col-md-2 d-flex justify-content-end\"],[10,\"style\",\"line-height:26px\"],[8],[0,\"\\n      \"],[1,[22,\"user-menu\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/top-navigation.hbs"
    }
  });

  _exports.default = _default;
});