define("job-ag/templates/components/link-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hK2DWgyn",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"bs-modal-simple\",null,[[\"title\",\"closeTitle\",\"onHidden\",\"open\",\"submitTitle\",\"class\"],[[24,[\"title\"]],\"Luk\",[24,[\"cancel\"]],\"true\",[24,[\"title\"]],\"alwaysScrollY\"]],{\"statements\":[[4,\"validated-form\",null,[[\"classNames\",\"model\",\"on-submit\",\"submit-label\"],[\"row\",[28,\"changeset\",[[24,[\"link\"]],[24,[\"validation\"]]],null],[24,[\"save\"]],\"hiddenSave\"]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/components/link-form.hbs' @ L15:C6) \"],null]],[[\"label\",\"required\",\"placeholder\",\"name\",\"class\"],[\"Titel\",true,\"Titel\",\"titel\",\"col-md-12\"]]],false],[0,\"\\n   \\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('job-ag/templates/components/link-form.hbs' @ L21:C6) \"],null]],[[\"label\",\"required\",\"placeholder\",\"name\",\"type\",\"class\"],[\"Link\",true,\"Linket (en URL adresse)\",\"url\",\"url\",\"col-md-12\"]]],false],[0,\"\\n  \\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[1,[22,\"liquid-outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "job-ag/templates/components/link-form.hbs"
    }
  });

  _exports.default = _default;
});