define("job-ag/components/pager-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    page: 0,
    total: 0,
    perPage: 0,
    showPagerTopElement: true,
    showPerPageChooser: Ember.computed('total', function () {
      return this.total > 0;
    }),
    currentPage: Ember.computed('page', function () {
      return this.page + 1;
    }),
    endPage: Ember.computed('total', 'perPage', function () {
      let endPage = Math.trunc(this.total / this.perPage);

      if (this.total % this.perPage > 0) {
        endPage++;
      }

      return endPage;
    }),
    showPager: Ember.computed('total', 'perPage', function () {
      return this.total > this.perPage;
    }),
    showTop: Ember.computed('showPager', 'showTopPager', function () {
      return this.showPager && this.showTopPager;
    }),
    showBottom: Ember.computed('showPager', 'showBottomPager', function () {
      return this.showPager && this.showBottomPager;
    }),
    showStart: Ember.computed('showStartButton', 'total', 'page', function () {
      let result = this.showStartButton;
      result = result && this.page !== 0;
      return result;
    }),
    showPrev: Ember.computed('total', 'page', function () {
      let result = true;
      result = result && this.page !== 0;
      return result;
    }),
    showNext: Ember.computed('currentPage', 'endPage', function () {
      let result = true;
      result = result && this.currentPage < this.endPage;
      return result;
    }),
    showEnd: Ember.computed('showEndButton', 'currentPage', 'endPage', function () {
      let result = this.showEndButton;
      result = result && this.currentPage != this.endPage;
      return result;
    }),
    actions: {
      setPerPage(faktor) {
        let perPage = Math.trunc(this.perPage * faktor);

        if (perPage > 0) {
          this.set('perPage', perPage);
          this.set('page', 0); // window.scrollTo(0, 0);
        }
      },

      updateTotal(newTotal) {
        this.set('total', newTotal);
      },

      startPage() {
        this.set('page', 0); // window.scrollTo(0, 0);
      },

      goEndPage() {
        this.set('page', this.endPage - 1); // window.scrollTo(0, 0);
      },

      prevPage() {
        if (this.page > 0) {
          this.decrementProperty('page');
        } // window.scrollTo(0, 0);

      },

      nextPage() {
        this.incrementProperty('page'); // window.scrollTo(0, 0);
      }

    }
  });

  _exports.default = _default;
});