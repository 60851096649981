define("job-ag/models/exchangekonto", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    link: _emberData.default.attr('string'),
    brugernavn: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    domain: _emberData.default.attr('string'),
    ldapdomain: _emberData.default.attr('string'),
    emailDomain: _emberData.default.attr('string'),
    aktiv: _emberData.default.attr('boolean'),
    exchangeVersion: _emberData.default.attr('string'),
    identifier: Ember.computed('id', {
      get() {
        return `customContent${this.id}`;
      }

    }),
    identifierWithType: Ember.computed('id', {
      get() {
        return `.customContent${this.id}`;
      }

    })
  });

  _exports.default = _default;
});