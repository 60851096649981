define("job-ag/models/companyrecruitmentprovidertype", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cvrnumberIdentifierField: _emberData.default.attr(),
    productionUnitIdentifierField: _emberData.default.attr() //	companyRecruitmentProviderField: DS.belongsTo('joborder')

  });

  _exports.default = _default;
});