define("job-ag/components/card-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    sortBy: '',
    sortDirection: 'ASC',
    filtered: Ember.computed(function () {
      let all = Ember.get(this, 'model');
      return all;
    }),
    filteredAndSorted: Ember.computed('filtered', function () {
      return Ember.get(this, 'filtered');
    })
  });

  _exports.default = _default;
});